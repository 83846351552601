import { FormProvider, useForm } from 'react-hook-form';

// ** MUI Imports
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TableContainer from '@mui/material/TableContainer';

// ** Icons Imports
import InformationOutline from 'mdi-material-ui/InformationOutline';

// ** Component
import TextForm from '../../../../components/TextForm/TextForm.component';
import CheckboxForm from '../../../../components/CheckboxForm/CheckboxForm.component';

const rolesArr = [
  { label: 'User Management', value: 'userManagement' },
  { label: 'Content Management', value: 'contentManagement' },
  { label: 'Disputes Management', value: 'disputesManagement' },
  { label: 'Database Management', value: 'databaseManagement' },
  { label: 'Financial Management', value: 'financialManagement' },
  { label: 'Reporting', value: 'reporting' },
  { label: 'API Control', value: 'apiControl' },
  { label: 'Repository Management', value: 'repositoryManagement' },
  { label: 'Payroll', value: 'payroll' },
];

interface Props {
  open: boolean;
  dialogTitle: string;
  handleClose: () => void;
}

function FormModal({ open, dialogTitle, handleClose }: Props) {
  const methods = useForm();

  const onSubmit = (_: any) => {
    // console.log(data)
  };

  return (
    <Dialog fullWidth maxWidth='md' scroll='body' onClose={handleClose} open={open}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle sx={{ textAlign: 'center' }}>
            <Typography variant='h4' component='span'>
              {`${dialogTitle} Role`}
            </Typography>
            <Typography variant='body2'>Set Role Permissions</Typography>
          </DialogTitle>
          <DialogContent sx={{ p: { xs: 6, sm: 12 } }}>
            <Box sx={{ my: 4 }}>
              <TextForm fullWidth name='name' label='Role Name' />
            </Box>
            <Typography variant='h6'>Role Permissions</Typography>
            <TableContainer>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ pl: '0 !important' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          fontSize: '0.875rem',
                          alignItems: 'center',
                          textTransform: 'capitalize',
                        }}
                      >
                        Administrator Access
                        <Tooltip placement='top' title='Allows a full access to the system'>
                          <InformationOutline sx={{ ml: 1, fontSize: '1rem' }} />
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell colSpan={3}>
                      <CheckboxForm
                        name='selectAll'
                        label='Select All'
                        sx={{
                          '& .MuiTypography-root': {
                            textTransform: 'capitalize',
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rolesArr.map((role, index) => {
                    return (
                      <TableRow key={index} sx={{ '& .MuiTableCell-root:first-of-type': { pl: 0 } }}>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            color: theme => `${theme.palette.text.primary} !important`,
                          }}
                        >
                          {role.label}
                        </TableCell>
                        <TableCell>
                          <CheckboxForm name={`${role.value}.read`} label='Read' />
                        </TableCell>
                        <TableCell>
                          <CheckboxForm name={`${role.value}.write`} label='Write' />
                        </TableCell>
                        <TableCell>
                          <CheckboxForm name={`${role.value}.create`} label='Create' />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions sx={{ pt: 0, display: 'flex', justifyContent: 'center' }}>
            <Box className='demo-space-x'>
              <Button size='large' type='submit' variant='contained'>
                Submit
              </Button>
              <Button size='large' color='secondary' variant='outlined' onClick={handleClose}>
                Discard
              </Button>
            </Box>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}

export default FormModal;
