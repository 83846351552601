import { createContext } from 'react';
// Mui
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Close from 'mdi-material-ui/Close';
// Components
import NavItem from '../NavItem/NavItem.component';
// Import to Component
import navItems from '../../../../utils/navItems.utils';
import { breakpoints } from '../../../../utils/customTheme.util';

interface Props {
  drawerWidth: number;
  open: boolean;
  handleDrawerToggle: () => void;
  container: (() => HTMLElement) | undefined;
}

interface Context {
  closeDrawerMobile: () => void;
}

export const NavigationContext = createContext<Context>({
  closeDrawerMobile: () => {
    return null;
  },
});

const items = navItems();

function Navigation({ drawerWidth, open, handleDrawerToggle, container }: Props) {
  const { sm } = breakpoints;

  const mobile = window.innerWidth < sm;

  const context = {
    closeDrawerMobile: () => {
      if (mobile) handleDrawerToggle();
    },
  };

  // ** Drawer
  const drawer = (handleDrawerToggle: () => void) => {
    return (
      <div>
        <Box sx={{ display: 'flex', flexDirection: 'row', mx: '0.5rem' }}>
          <ListItemText primary='Ctis' />
          <IconButton onClick={handleDrawerToggle}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <NavItem items={items} />
      </div>
    );
  };

  return (
    <Box component='nav' sx={{ width: { sm: 0 }, flexShrink: { sm: 0 } }} aria-label='mailbox folders'>
      <NavigationContext.Provider value={context}>
        <Drawer
          container={container}
          variant={mobile ? 'temporary' : 'persistent'}
          anchor='left'
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: mobile,
          }}
          sx={{
            ...(mobile
              ? { display: { xs: 'block', sm: 'none' } }
              : {
                  display: { xs: 'none', sm: 'block' },
                  width: drawerWidth,
                  flexShrink: 0,
                }),
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer(handleDrawerToggle)}
        </Drawer>
      </NavigationContext.Provider>
    </Box>
  );
}

export default Navigation;
