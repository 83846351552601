// Imports
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Mui
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';

// Component
import UserForm from '../../views/UserForm/UserForm.view';
import { alertSuccess, alertLoading, alertError } from '../../components/ToasterMessage/ToasterMessage.component';

// Import to Component
import { initialValues, schemeValidation } from './utils/addUserValidation.util';
import { useCreateUser } from '../../hooks/users';
import { IUserForm } from '../../models/users/user-form.model';

const useParamCreate = {
  alertSuccess: (message: string) => alertSuccess(message),
  alertError: (message: string) => alertError(message),
};

function AddUser() {
  const [addUser, data, loading, error] = useCreateUser(useParamCreate);
  const methods = useForm<IUserForm>({
    defaultValues: initialValues,
    resolver: yupResolver(schemeValidation),
  });

  const onSubmit = (data: IUserForm) => {
    addUser(data);
  };

  if (error) alertError('An error has ocurred');
  if (data) alertSuccess('User created');
  if (loading) alertLoading('...loading');

  return (
    <Box style={{ maxWidth: '100%' }}>
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <UserForm add title='Add User' />
          </form>
        </FormProvider>
      </Card>
    </Box>
  );
}

export default AddUser;
