import { gql, useMutation, ApolloError } from '@apollo/client';

interface IMutation {
  id: number;
}

interface IParams {
  alertSuccess: (message: string) => void;
  alertError: (message: string) => void;
}

const DELETE_USER = gql`
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const useDeleteUser = ({
  alertSuccess,
  alertError,
}: IParams): [
  request: (value: number) => void,
  data: IMutation | null | undefined,
  loading: boolean,
  error: ApolloError | undefined,
] => {
  const [deleteUser, { data, loading, error }] = useMutation<IMutation>(DELETE_USER);

  const request = (id: number) => {
    deleteUser({
      variables: {
        id,
      },
      onCompleted: () => {
        alertSuccess('User deleted');
      },
      onError: () => {
        alertError('An error has ocurred');
      },
    });
  };
  return [request, data, loading, error];
};
