import { gql, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { IUserForm } from '../../models/users/user-form.model';
import { IState } from '../../models/state';

interface IMutation extends IUserForm {
  userId: number;
}

interface IParams {
  alertSuccess: (message: string) => void;
  alertError: (message: string) => void;
}

const CREATE_USER = gql`
  mutation createUser(
    $userId: Int!
    $name: String!
    $email: String!
    $address: String!
    $phone: String!
    $country: String!
    $postalCode: String!
    $user: String!
    $password: String!
    $state: String!
    $city: String!
  ) {
    createUser(
      createUserInput: {
        userId: $userId
        name: $name
        email: $email
        address: $address
        phone: $phone
        countryAlphacode: $country
        postalCode: $postalCode
        user: $user
        password: $password
        state: $state
        city: $city
      }
    ) {
      userId
      name
      email
      address
      phone
      countryAlphacode
      postalCode
      user
      password
      state
      city
    }
  }
`;

export const useCreateUser = ({ alertSuccess, alertError }: IParams) => {
  const [createUser, { data, loading, error }] = useMutation<IMutation>(CREATE_USER);
  const session = useSelector((state: IState) => state.userSession);

  const addUser = (data: IUserForm) => {
    createUser({
      variables: { ...data, userId: session.id },
      onCompleted: () => {
        alertSuccess('User Created');
      },
      onError: () => {
        alertError('An error has ocurred');
      },
    });
  };
  return [addUser, data, loading, error] as const;
};
