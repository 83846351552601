// ** Imports
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// ** Mui
import Autocomplete from '@mui/material/Autocomplete';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';

// ** Import to Component
import { IItemsAutocompleteArray } from '../../models/common';

interface Props<T> extends StandardTextFieldProps {
  name: string;
  options: IItemsAutocompleteArray<T>[];
  onChangeItem: (value: T) => void;
  setValue?: (value: string) => void;
  loading?: boolean;
  spinner?: boolean;
}

function AutocompleteText<T>({ name, loading, spinner, options, onChangeItem, setValue, ...props }: Props<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      {!loading ? (
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange, ...otherField } }) => (
            <Autocomplete
              freeSolo
              disablePortal
              loading={spinner}
              options={options}
              value={value}
              onInputChange={async (event, value) => {
                if (setValue) setValue(value);
              }}
              onChange={(event, item: IItemsAutocompleteArray<T>) => {
                onChangeItem(item.select);
              }}
              {...otherField}
              renderInput={params => (
                <TextField
                  {...params}
                  {...props}
                  error={!!errors[name]}
                  helperText={errors[name] ? errors[name]?.message : ''}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {spinner ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          )}
        />
      ) : (
        <Skeleton variant='rectangular' height={60} />
      )}
    </>
  );
}
export default AutocompleteText;
