// ** Imports
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

// ** Mui
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// ** Component
import TextForm from '../../../../components/TextForm/TextForm.component';
import { alertSuccess, alertError, toast } from '../../../../components/ToasterMessage/ToasterMessage.component';

// ** Import to Component
import { addSession } from '../../../../redux/slices/userSession.slice';
import { FormLogin as Form } from '../../../../models/login/form-login.model';
import { useLogin } from '../../../../hooks/login/useLogin.hook';
import validationScheme from '../../utils/loginValidation.util';

// ** Styled Component
import { BoxWrapper, TypographyStyled } from '../../styled.component';

function FormLogin() {
  const [request] = useLogin();
  const dispatch = useDispatch();
  const methods = useForm<Form>({
    defaultValues: { email: 'admin@cmlexports.com', password: 'Abc.123' },
    resolver: yupResolver(validationScheme),
  });

  const onSubmit = async (data: Form) => {
    const toastId = toast.loading('Loading');
    const { dataLogin, error } = await request(data);
    if (!error && dataLogin) {
      dispatch(addSession(dataLogin));
      toast.dismiss(toastId);
      alertSuccess('Success');
    }
    alertError(`Error ${error.message}`);
  };

  return (
    <>
      <BoxWrapper>
        <Box sx={{ mb: 6 }}>
          <TypographyStyled variant='h5'>Welcome to Ctis</TypographyStyled>
          <Typography variant='body2'>Please sign-in to your account</Typography>
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <TextForm fullWidth label='Email' name='email' />
                </Grid>
                <Grid item xs={12}>
                  <TextForm fullWidth label='Password' type='password' name='password' />
                </Grid>
                <Grid item xs={12}>
                  <Button size='large' type='submit' sx={{ width: '100%' }} variant='contained'>
                    Login
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </FormProvider>
      </BoxWrapper>
    </>
  );
}

export default FormLogin;
