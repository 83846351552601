import React from 'react';

// ** MUI Imports
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

// ** Components
import RadioGroup from '../../components/RadioGroup/RadioGroup.component';
import TextForm from '../../components/TextForm/TextForm.component';
import DatePicker from '../../components/DatePicker/DatePicker.component';

function QuoteFilters() {
  return (
    <Grid container>
      <Grid item xs={10} sx={{ mx: 'auto', my: 2 }}>
        <Grid
          container
          sx={{
            py: 2,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography sx={{ px: 1 }}>Status: </Typography>
          <RadioGroup name='status' sx={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel value='1' control={<Radio />} label='Active' />
            <FormControlLabel value='0' control={<Radio />} label='Cancelled' />
          </RadioGroup>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={3}>
            <TextForm name='account' label='Account' />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextForm name='customer' label='Customer Name' />
          </Grid>
          <Grid item xs={6} sm={3}>
            <DatePicker label='Start' name='startDate' />
          </Grid>
          <Grid item xs={6} sm={3}>
            <DatePicker label='End' name='endDate' />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default QuoteFilters;
