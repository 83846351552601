import { ICustomerFormQuote } from '../../models/customer';
import { IPlannedDatesFormQuote } from '../../models/plannedDates';
import { IActivitiesFormQuote } from '../../models/orderActivity';
import {
  IQuoteById,
  IQuoteSelectForm,
  IAddressFormQuote,
  IDetailsFormQuote,
  IQuoteArticlesTable,
} from '../../models/orders';

export function findQuoteAdapter(quote: IQuoteById) {
  const selects: IQuoteSelectForm = {
    accountId: String(quote.accountId),
    billTo: quote.invoiceTo === 1 ? 'account' : 'customer',
    typeOfService: quote.typeLocation,
  };

  const { countryAlphacode, ...dataCustomer } = quote.customer;
  const customer: ICustomerFormQuote = {
    country: countryAlphacode,
    ...dataCustomer,
  };

  const address: IAddressFormQuote = {
    address: quote.address,
    city: quote.city,
    country: quote.countryAlphacode,
    postalCode: quote.postalCode,
    state: quote.state,
  };

  const detailForm: IDetailsFormQuote = {
    cubicFoot: quote.cubFoot || undefined,
    estimatedCost: quote.price,
    estimatedValue: quote.estimatedValue || undefined,
    estimatedDate: quote.workDate,
    typePayment: String(quote.typePayment),
    startTimer: quote.workBegins || undefined,
    endTime: quote.workEnds || undefined,
    estimatedMiles: quote.miles || undefined,
    estimatedWeight: quote.weight || undefined,
    weightDistribution: quote.distWeight || undefined,
  };

  const planned: {
    id?: number;
    details: string;
    start: Date;
    end: Date;
  }[] = quote.plannedDates.map(date => {
    return {
      id: date.id,
      details: date.details,
      start: date.start,
      end: date.end,
    };
  });

  const plannedDates: IPlannedDatesFormQuote = {
    plannedDates: planned,
  };

  const activities: {
    id?: number;
    details: string;
    start: Date;
    end: Date;
  }[] = quote.activities.map(activity => {
    return {
      id: activity.id,
      details: String(activity.activityId),
      start: activity.start,
      end: activity.end,
    };
  });

  const activity: IActivitiesFormQuote = {
    activities,
  };

  const articles: IQuoteArticlesTable[] = quote.details.map((detail, index) => {
    return {
      rowId: detail.id,
      id: detail.articleId,
      article: detail.type === '1' ? 'product' : 'service',
      description: detail.description,
      insurance: detail.insurance,
      name: detail.name,
      number: index + 1,
      quantity: detail.quantity,
      subTotal: detail.subtotal,
      changePrice: false,
      safe: detail.insurance === 0,
      tax: detail.taxation === 0,
      taxation: detail.taxation,
      total: detail.total,
      type: detail.typeArticle,
      unitPrice: detail.unitaryPrice,
      height: detail.height ? detail.height : undefined,
      hight: detail.high ? detail.high : undefined,
      width: detail.width ? detail.width : undefined,
      weight: detail.weight ? detail.weight : undefined,
      reWeight: detail.reWeight ? detail.reWeight : undefined,
      typeOfLoad: detail.loadType,
      content: detail.content ? detail.content : '',
    };
  });

  return {
    selects,
    customer,
    address,
    detailForm,
    plannedDates,
    activity,
    articles,
  };
}
