// ** Imports
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useNavigate } from 'react-router-dom';

// ** Mui
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';

// ** Component
import UserForm from '../../views/UserForm/UserForm.view';
import { alertSuccess, alertError } from '../../components/ToasterMessage/ToasterMessage.component';

// ** Import to Component
import { useFindUserById, useUpdateUser } from '../../hooks/users';
import { IUserUpdateForm } from '../../models/users/user-form.model';
import { schemeValidation } from './utils/updateUserValidation.util';

const useParamUpdate = {
  alertSuccess: (message: string) => alertSuccess(message),
  alertError: (message: string) => alertError(message),
};

function UpdateUser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateUser, errorUpdate] = useUpdateUser(useParamUpdate);
  const methods = useForm<IUserUpdateForm>({
    resolver: yupResolver(schemeValidation),
  });
  const [findUser, loadingFind, errorFind] = useFindUserById(methods.reset);

  useEffect(() => {
    if (id) {
      findUser(parseInt(id));
    }
  }, [id]);

  const onSubmit = (data: IUserUpdateForm) => {
    if (id) {
      data.id = parseInt(id);
      updateUser(data);
      if (!errorUpdate) navigate('/user/list');
    }
  };

  if (errorFind) return <div>Error</div>;

  if (loadingFind) return <div>loading</div>;

  return (
    <Box style={{ maxWidth: '100%' }}>
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <UserForm add={false} title='Update User' />
          </form>
        </FormProvider>
      </Card>
    </Box>
  );
}

export default UpdateUser;
