import toast, { Toaster } from 'react-hot-toast';
import Alert from '@mui/material/Alert';

export { toast };

export const alertSuccess = (message: string) => toast.custom(() => <Alert severity='success'>{message}</Alert>);

export const alertError = (message: string) => toast.custom(() => <Alert severity='error'>{message}</Alert>);

export const alertLoading = (message: string) => {
  toast.loading(message);
};

function ToasterMessage() {
  return <Toaster toastOptions={{ duration: 5000 }} />;
}

export default ToasterMessage;
