// ** MUI Imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

// ** Icons Imports
import Plus from 'mdi-material-ui/Plus';

// Import to Component
import { IListProducts, IProductFormQuote } from '../../models/products';

interface RenderCell {
  row: IListProducts;
}

export const defaultColumns = (handleProduct: (values: IProductFormQuote) => void) => {
  return [
    {
      flex: 0.15,
      minWidth: 120,
      sortable: false,
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color='info'
              onClick={() => {
                handleProduct({
                  id: row.id,
                  type: row.type,
                  name: row.name,
                  description: row.description,
                  unitPrice: row.price,
                  changePrice: false,
                });
              }}
            >
              <Plus fontSize='large' />
            </IconButton>
          </Box>
        );
      },
    },
    {
      flex: 0.2,
      field: 'id',
      headerName: 'Id',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography noWrap variant='body2'>
            {row.id}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      field: 'type',
      headerName: 'Type',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Typography
              noWrap
              component='a'
              variant='body2'
              sx={{
                fontWeight: 600,
                color: 'text.primary',
                textDecoration: 'none',
              }}
            >
              {row.type}
            </Typography>
          </Box>
        );
      },
    },
    {
      flex: 0.2,
      field: 'name',
      headerName: 'Name',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Typography
              noWrap
              component='a'
              variant='body2'
              sx={{
                fontWeight: 600,
                color: 'text.primary',
                textDecoration: 'none',
              }}
            >
              {row.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      flex: 0.2,
      field: 'description',
      headerName: 'Description',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.description}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      field: 'cost',
      headerName: 'Cost',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.cost}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      field: 'price',
      headerName: 'Price',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.price}
          </Typography>
        );
      },
    },
  ];
};
