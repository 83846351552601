import { FormProvider } from 'react-hook-form';

// ** Mui
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';

// ** Component
import TextForm from '../../components/TextForm/TextForm.component';
import SelectForm from '../../components/SelectForm/SelectForm.component';
import ButtonForm from '../../components/ButtonForm/ButtonForm.component';
import CheckboxForm from '../../components/CheckboxForm/CheckboxForm.component';
import IconButton from '../../components/IconButton/IconButton.component';

// ** Icons
import PlusCircle from 'mdi-material-ui/PlusCircle';
import Lock from 'mdi-material-ui/Lock';

// ** Views
import ServicesModal from '../ServicesModal/ServicesModal.view';
import ChangePrice from '../ChangePrice/ChangePrice.component';

// ** Import to Component
import { IServiceFormQuote } from '../../models/services';
import { IArticlesFormQuote } from '../../models/orders';
import { useQuoteService } from './hooks/useQuoteService.hook';

const itemsLoad = [
  { value: 'regular', label: 'Regular' },
  { value: 'residencial', label: 'Residential' },
  { value: 'comercial', label: 'Commercial' },
];

interface Props {
  detailsView: () => void;
  addArticle: (values: IArticlesFormQuote) => void;
  updateArticle: (values: IArticlesFormQuote, number: number | undefined) => void;
  values: IServiceFormQuote | undefined;
}

function QuoteService({ detailsView, addArticle, updateArticle, values }: Props) {
  const [
    open,
    openChangePrice,
    unitPrice,
    methods,
    handleService,
    handleClickOpen,
    handleClose,
    handleOpenChangePrice,
    handleCloseChangePrice,
    handleUpdate,
    onSubmit,
  ] = useQuoteService({ addArticle, updateArticle, values });

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} sm={10} sx={{ mx: 'auto' }}>
              <Card>
                <CardHeader title='Service Details' titleTypographyProps={{ variant: 'h6' }} />
                <Divider sx={{ margin: 0 }} />
                <CardContent>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <IconButton icon={PlusCircle} onClick={handleClickOpen} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextForm fullWidth name='type' label='Service Type' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextForm fullWidth name='name' label='Service' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextForm fullWidth name='description' label='Description' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectForm name='typeOfLoad' label='Type Of Load' items={itemsLoad} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextForm fullWidth name='quantity' label='Quantity' type='number' />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'row' }}>
                      <TextForm fullWidth name='unitPrice' label='Unit Price' type='number' disabled />
                      <IconButton icon={Lock} sx={{ ml: 1 }} onClick={handleOpenChangePrice} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextForm fullWidth name='height' label='Height' type='number' />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextForm fullWidth name='hight' label='Hight' type='number' />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextForm fullWidth name='width' label='Width' type='number' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextForm fullWidth name='weight' label='Weight' type='number' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextForm fullWidth name='reWeight' label='Re-Weight' type='number' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CheckboxForm name='safe' label='Apply Safe' />
                      <CheckboxForm name='tax' label='Apply Tax' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextForm fullWidth name='content' label='Content' />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <TextForm name='totalPrice' label='Total Price' type='number' />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider sx={{ margin: 0 }} />
                <CardActions sx={{ padding: '1rem' }}>
                  <ButtonForm label={!values ? 'Add' : 'Update'} type='submit' sx={{ mr: 2 }} />
                  <ButtonForm size='large' color='info' label='Reviewing' onClick={detailsView} />
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <ServicesModal dialogTitle='Services List' open={open} handleClose={handleClose} handleService={handleService} />
      <ChangePrice
        dialogTitle='Change Price'
        open={openChangePrice}
        handleClose={handleCloseChangePrice}
        handleUpdate={handleUpdate}
        currentPrice={unitPrice}
      />
    </>
  );
}

export default QuoteService;
