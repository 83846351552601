import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// ** Mui
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';

// ** Component
import TextForm from '../../components/TextForm/TextForm.component';
import SelectForm from '../../components/SelectForm/SelectForm.component';
import DatePicker from '../../components/DatePicker/DatePicker.component';
import TimePicker from '../../components/TimePicker/TimePicker.component';
import ButtonForm from '../../components/ButtonForm/ButtonForm.component';

// ** Import to Component
import { IDetailsFormQuote } from '../../models/orders';
import { initialValues, schemeValidation } from './utils/schemeValidation.util';

interface Props {
  nextStep: () => void;
  backStep: () => void;
  values: IDetailsFormQuote | undefined;
  setValues: (value: IDetailsFormQuote) => void;
}

const PaymentMethods = [
  { value: '0', label: 'Cash' },
  { value: '1', label: 'Bank Transfer' },
  { value: '2', label: 'Debit Card' },
  { value: '3', label: 'Credit Card' },
  { value: '4', label: 'Check' },
];

function QuoteFormDetails({ backStep, nextStep, values, setValues }: Props) {
  const methods = useForm<IDetailsFormQuote>({
    defaultValues: values || initialValues,
    resolver: yupResolver(schemeValidation),
  });

  const onSubmit = (data: IDetailsFormQuote) => {
    setValues(data);
    nextStep();
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} sm={10} sx={{ mx: 'auto' }}>
            <Card>
              <CardHeader title='Job Information' titleTypographyProps={{ variant: 'h6' }} />
              <Divider sx={{ margin: 0 }} />
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='estimatedCost' label='Estimated Cost' type='number' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker fullWidth name='estimatedDate' label='Estimated Date' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='estimatedValue' label='Estimated Value' type='number' />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid item xs={6} sx={{ mr: 1 }}>
                      <TimePicker fullWidth name='startTimer' label='Start Time' />
                    </Grid>
                    <Grid item xs={6} sx={{ ml: 1 }}>
                      <TimePicker fullWidth name='endTime' label='End Time' />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='cubicFoot' label='Cubic Foot' type='number' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectForm name='typePayment' label='Payment Method' items={PaymentMethods} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='estimatedWeight' label='Estimated Weight' type='number' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='estimatedMiles' label='Estimated Miles' type='number' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='storageInTransit' label='Storage in Transit (#)' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='weightDistribution' label='Weight Distribution' type='number' />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider sx={{ margin: 0 }} />
              <CardActions sx={{ justifyContent: 'space-between', padding: '1rem' }}>
                <ButtonForm size='large' color='secondary' variant='outlined' onClick={backStep} label='Back' />
                <ButtonForm label='Next' type='submit' sx={{ mr: 2 }} />
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default QuoteFormDetails;
