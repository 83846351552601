import { gql, useQuery } from '@apollo/client';
import { IAccountList } from '../../models/accounts';

interface IFindAccounts {
  accounts: IAccountList[];
}

const FIND_ACCOUNTS = gql`
  query getAccounts {
    accounts {
      id
      name
      address
      countryAlphacode
      postalCode
      state
      city
      phone
      email
      minAmount
    }
  }
`;

export const useFindAccounts = () => {
  const { data, loading, error } = useQuery<IFindAccounts>(FIND_ACCOUNTS);

  return [data, loading, error] as const;
};
