import * as Yup from 'yup';

export const initialValues = {
  type: '',
  name: '',
  description: '',
  quantity: 0,
  unitPrice: 0,
  height: undefined,
  hight: undefined,
  width: undefined,
  safe: false,
  tax: false,
  content: '',
  totalPrice: 0,
  changePrice: false,
};

export const schemeValidation = Yup.object({
  type: Yup.string().required('You must enter a Product Type'),
  name: Yup.string().required('You must enter a Product Name'),
  description: Yup.string().required('You must enter a Product Description'),
  quantity: Yup.number().min(1, 'You must enter a Quantity'),
  unitPrice: Yup.number().required('You must enter a Unit Price'),
  height: Yup.number().positive('You must enter a positive number'),
  hight: Yup.number().positive('You must enter a positive number'),
  width: Yup.number().positive('You must enter a positive number'),
  safe: Yup.boolean(),
  tax: Yup.boolean(),
  content: Yup.string(),
  totalPrice: Yup.number().positive('You must enter a positive number').required('You must choose a Product'),
});
