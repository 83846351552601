import { gql, useLazyQuery } from '@apollo/client';
import { ICustomerByName, ICustomerFormQuote } from '../../models/customer';
import { IItemsAutocompleteArray } from '../../models/common';

interface IFindCustomer {
  customerByName: ICustomerByName[];
}

const FIND_CUSTOMER = gql`
  query getCustomerByName($name: String!, $accountId: Int!) {
    customerByName(name: $name, accountId: $accountId) {
      id
      accountId
      name
      address
      countryAlphacode
      postalCode
      state
      city
      phone
      email
    }
  }
`;

export const useFindCustomerByName = () => {
  const [find, { data, loading, error }] = useLazyQuery<IFindCustomer>(FIND_CUSTOMER);

  const findCustomer = (
    name: string,
    accountId: number,
    setValues: (values: IItemsAutocompleteArray<ICustomerFormQuote>[]) => void,
  ) => {
    find({
      variables: {
        name,
        accountId,
      },
      onCompleted: data => {
        const array: IItemsAutocompleteArray<ICustomerFormQuote>[] = [];
        data.customerByName.map(customer =>
          array.push({
            value: customer.id,
            label: customer.name,
            select: { country: customer.countryAlphacode, ...customer },
          }),
        );
        setValues(array);
      },
    });
  };

  return [findCustomer, data, loading, error] as const;
};
