import { FormProvider } from 'react-hook-form';

// ** Mui
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';

// ** Component
import TextForm from '../../components/TextForm/TextForm.component';
import ButtonForm from '../../components/ButtonForm/ButtonForm.component';
import SelectForm from '../../components/SelectForm/SelectForm.component';
import IconButton from '../../components/IconButton/IconButton.component';

// ** Icons
import SquareEditOutline from 'mdi-material-ui/SquareEditOutline';

// ** Import to Component
import { IItemsSelectArray } from '../../models/common';
import { IAccountFormQuote } from '../../models/accounts';
import { useQuoteAccount } from './hooks/useQuoteAccount.hook';

interface Props {
  nextStep: () => void;
  backStep: () => void;
  countries: IItemsSelectArray[];
  isOpsLocals: boolean;
  values: IAccountFormQuote | undefined;
  setValues: (value: IAccountFormQuote) => void;
}

function QuoteAccount({ backStep, nextStep, countries, isOpsLocals, values, setValues }: Props) {
  const [methods, onSubmit, disabled, handleCancel, handleClick, handleAction] = useQuoteAccount({
    backStep,
    nextStep,
    values,
    setValues,
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} sm={10} sx={{ mx: 'auto' }}>
            <Card>
              <CardHeader title={isOpsLocals ? 'Origin' : 'Account Data'} titleTypographyProps={{ variant: 'h6' }} />
              <Divider sx={{ margin: 0 }} />
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton icon={SquareEditOutline} onClick={handleClick} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='name' label='Name' disabled={disabled} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='address' label='Address' disabled={disabled} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectForm name='country' label='Country' items={countries} disabled={disabled} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='postalCode' label='Postal Code' disabled={disabled} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='state' label='State' disabled={disabled} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='city' label='City' disabled={disabled} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='phone' label='Phone' disabled={disabled} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='email' label='Email' disabled={disabled} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='numberOrder' label='Number Order' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='gbl' label='Gbl' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='workTicket' label='Work Ticket' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='reference' label='Reference' />
                  </Grid>
                  <Grid item xs={12}>
                    <TextForm fullWidth name='notes' label='Notes' />
                  </Grid>
                </Grid>
              </CardContent>
              {!disabled && (
                <>
                  <Divider sx={{ margin: 0 }} />
                  <CardActions
                    sx={{
                      justifyContent: 'space-between',
                      padding: '1rem',
                    }}
                  >
                    <ButtonForm
                      size='large'
                      color='secondary'
                      variant='outlined'
                      onClick={handleCancel}
                      label='Cancel'
                    />
                    <ButtonForm label='Update' sx={{ mr: 2 }} onClick={methods.handleSubmit(handleAction)} />
                  </CardActions>
                </>
              )}
              <Divider sx={{ margin: 0 }} />
              <CardActions sx={{ justifyContent: 'space-between', padding: '1rem' }}>
                <ButtonForm
                  size='large'
                  color='secondary'
                  variant='outlined'
                  onClick={backStep}
                  label='Back'
                  disabled={!disabled}
                />
                <ButtonForm label='Next' type='submit' sx={{ mr: 2 }} disabled={!disabled} />
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default QuoteAccount;
