import { useState } from 'react';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// ** Mui
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';

// ** Component
import SelectForm from '../../components/SelectForm/SelectForm.component';
import DatePicker from '../../components/DatePicker/DatePicker.component';
import ButtonForm from '../../components/ButtonForm/ButtonForm.component';

// ** Import to Component
import { initialValues, schemeValidation } from './utils/schemeValidation.util';
import { IActivitiesFormQuote } from '../../models/orders';
import { IItemsSelectArray } from '../../models/common';
import { useFindActivities } from '../../hooks/activities';

interface Props {
  nextStep: () => void;
  backStep: () => void;
  values: IActivitiesFormQuote | undefined;
  setValues: (value: IActivitiesFormQuote) => void;
}

function QuoteActivities({ backStep, nextStep, values, setValues }: Props) {
  const [options, setOptions] = useState<IItemsSelectArray[]>();

  const [, loading] = useFindActivities(setOptions);

  const methods = useForm<IActivitiesFormQuote>({
    defaultValues: values || initialValues,
    resolver: yupResolver(schemeValidation),
  });

  const { fields, append, remove } = useFieldArray({
    name: 'activities',
    control: methods.control,
  });

  const addActivity = () => {
    append({ details: '', start: new Date(), end: new Date() });
  };

  const deleteActivity = (index: number) => {
    remove(index);
  };

  const onSubmit = (data: IActivitiesFormQuote) => {
    setValues(data);
    nextStep();
  };
  return (
    <Card>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CardHeader title='Add Activity' titleTypographyProps={{ variant: 'h6' }} />
          <Divider sx={{ margin: 0 }} />
          {fields.map((field, index) => (
            <>
              <CardContent key={field.id}>
                <Grid container spacing={3}>
                  <Grid item xs={9}>
                    <SelectForm
                      key={field.id}
                      name={`activities[${index}].details`}
                      items={options || []}
                      label='Details'
                      loading={loading}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ButtonForm
                      size='large'
                      sx={{ mr: 2 }}
                      variant='contained'
                      color='error'
                      tabIndex={index}
                      onClick={() => deleteActivity(index)}
                      label='X'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker fullWidth key={field.id} name={`activities[${index}].start`} label='Start' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker fullWidth key={field.id} name={`activities[${index}].end`} label='End' />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider sx={{ margin: 0 }} />
            </>
          ))}
          <CardActions sx={{ padding: '1rem' }}>
            <ButtonForm size='large' sx={{ mr: 2 }} variant='contained' onClick={addActivity} label='Add' />
          </CardActions>
          <Divider sx={{ margin: 0 }} />
          <CardActions sx={{ justifyContent: 'space-between', padding: '1rem' }}>
            <ButtonForm size='large' color='secondary' variant='outlined' onClick={backStep} label='Back' />
            <ButtonForm label='Next' type='submit' sx={{ mr: 2 }} />
          </CardActions>
        </form>
      </FormProvider>
    </Card>
  );
}

export default QuoteActivities;
