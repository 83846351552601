import { gql, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { IAccountFormQuote } from '../../models/accounts';
import { IState } from '../../models/state';
import { alertError, alertSuccess } from '../../components/ToasterMessage/ToasterMessage.component';

interface IMutation extends IAccountFormQuote {
  userId: number;
}

const CREATE_ACCOUNT = gql`
  mutation createAccount(
    $userId: Int!
    $name: String!
    $email: String!
    $address: String!
    $phone: String!
    $country: String!
    $postalCode: String!
    $user: String!
    $password: String!
    $state: String!
    $city: String!
  ) {
    createAccount(
      createAccountInput: {
        userId: $userId
        name: $name
        email: $email
        address: $address
        phone: $phone
        countryAlphacode: $country
        postalCode: $postalCode
        user: $user
        password: $password
        state: $state
        city: $city
      }
    ) {
      userId
      name
      email
      address
      phone
      countryAlphacode
      postalCode
      user
      password
      state
      city
    }
  }
`;

export const useCreateAccount = () => {
  const session = useSelector((state: IState) => state.userSession);

  const [create, { data, loading, error }] = useMutation<IMutation>(CREATE_ACCOUNT);

  const createAccount = (account: IAccountFormQuote) => {
    create({
      variables: { userId: session.id, ...account },
      onCompleted: () => {
        alertSuccess('User Created');
      },
      onError: () => {
        alertError('An error has ocurred');
      },
    });
  };

  return [createAccount, data, loading, error] as const;
};
