import { ReactNode } from 'react';

// ** Mui
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

interface Props extends DialogProps {
  open: boolean;
  dialogTitle: string;
  handleClose: () => void;
  children?: ReactNode;
}

function DialogModal({ open, dialogTitle, handleClose, children, ...props }: Props) {
  return (
    <Dialog onClose={handleClose} open={open} {...props}>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography variant='h4' component='span'>
          {dialogTitle}
        </Typography>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default DialogModal;
