import { useState } from 'react';

// ** MUI Imports
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// ** Components
import RenderCards from './components/RenderCards/RenderCards.component';
import AddRole from './components/AddRole/AddRole.component';
import FormModal from './components/FormModal/FormModal.component';
import PageHeader from '../../components/PageHeader/PageHeader.component';

// ** Import to Component
import json from '../../fake-data/card-data.json';

const cardData = json.cardData;

function Roles() {
  // ** States
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('Add');

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container spacing={6}>
      <PageHeader
        title={<Typography variant='h5'>Roles List</Typography>}
        subTitle={
          <Typography variant='body2'>
            A role provided access to predefined menus and features so that depending on assigned role an administrator
            can have access to what he need
          </Typography>
        }
      />
      <Grid container spacing={6} sx={{ m: 2 }}>
        <RenderCards cardData={cardData} handleClickOpen={handleClickOpen} setDialogTitle={setDialogTitle} />
        <AddRole handleClickOpen={handleClickOpen} setDialogTitle={setDialogTitle} />
        <FormModal open={open} dialogTitle={dialogTitle} handleClose={handleClose} />
      </Grid>
    </Grid>
  );
}

export default Roles;
