// ** React Imports
import { Fragment, useState } from 'react';

// ** MUI Imports
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

// ** Icons Imports
import Translate from 'mdi-material-ui/Translate';

const layout = 'vertical';
const direction = 'ltr';

const LanguageDropdown = () => {
  // ** State
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLangDropdownOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLangDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleLangItemClick = (_: string) => {
    handleLangDropdownClose();
  };

  return (
    <Fragment>
      <IconButton
        color='inherit'
        aria-haspopup='true'
        aria-controls='customized-menu'
        onClick={handleLangDropdownOpen}
        sx={layout === 'vertical' ? { mr: 0.75 } : { mx: 0.75 }}
      >
        <Translate />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleLangDropdownClose}
        sx={{ '& .MuiMenu-paper': { mt: 4, minWidth: 130 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: direction === 'ltr' ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: direction === 'ltr' ? 'right' : 'left',
        }}
      >
        <MenuItem
          sx={{ py: 2 }}
          //   selected={i18n.language === "en"}
          onClick={() => {
            handleLangItemClick('en');
          }}
        >
          English
        </MenuItem>
        <MenuItem
          sx={{ py: 2 }}
          //   selected={i18n.language === "fr"}
          onClick={() => {
            handleLangItemClick('fr');
          }}
        >
          Spanish
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default LanguageDropdown;
