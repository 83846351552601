import { gql, useQuery } from '@apollo/client';
import { usePagination } from '../common';
import { IQuoteFilters } from '../../models/orders';

// Export to subscription
export interface FindOrders<T> {
  ordersByType: {
    rows: T[];
    total: number;
  };
}

// Export to subscription
export const FIND_QUOTES = gql`
  query getOrdersByType(
    $page: Int!
    $take: Int!
    $type: String!
    $status: Int!
    $account: String!
    $customer: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    ordersByType(
      take: $take
      page: $page
      type: $type
      status: $status
      account: $account
      customer: $customer
      startDate: $startDate
      endDate: $endDate
    ) {
      rows {
        id
        identifier
        type
        subtotal
        total
      }
      total
    }
  }
`;

export const useOrderFindByType = <T>(type: string, filters: IQuoteFilters) => {
  const [queryOptions, setPage, setPageSize, setRowCount] = usePagination();

  const { status, ...othersFilters } = filters;

  const { data, loading, error } = useQuery<FindOrders<T>>(FIND_QUOTES, {
    variables: {
      page: queryOptions.page,
      take: queryOptions.pageSize,
      type,
      status: parseInt(status),
      ...othersFilters,
    },
    onCompleted(data) {
      setRowCount(data.ordersByType.total);
    },
  });

  return [data, loading, error, queryOptions, setPage, setPageSize] as const;
};
