import * as Yup from 'yup';

export const initialValues = {
  estimatedCost: undefined,
  estimatedDate: undefined,
  estimatedValue: undefined,
  startTimer: undefined,
  endTime: undefined,
  cubicFoot: undefined,
  typePayment: '',
  estimatedWeight: undefined,
  estimatedMiles: undefined,
  storageInTransit: '',
  weightDistribution: undefined,
};

export const schemeValidation = Yup.object({
  estimatedCost: Yup.number().positive('You must enter a positive number').required('You must enter a Estimated Cost'),
  estimatedDate: Yup.date().required('You must enter a Estimated Date'),
  estimatedValue: Yup.number().positive('You must enter a positive number'),
  startTimer: Yup.date(),
  endTime: Yup.date(),
  cubicFoot: Yup.number().positive('You must enter a positive number'),
  typePayment: Yup.string().required('You must enter a Payment Method'),
  estimatedWeight: Yup.number().positive('You must enter a positive number'),
  estimatedMiles: Yup.number().positive('You must enter a positive number'),
  storageInTransit: Yup.string(),
  weightDistribution: Yup.number().positive('You must enter a positive number'),
});
