import React from 'react';

// ** Mui
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';

interface Props extends ButtonProps {
  label: string;
  loading?: boolean;
  spinner?: boolean;
}

function ButtonForm({ label, variant = 'contained', size = 'large', spinner, loading, ...props }: Props) {
  return (
    <>
      {!loading ? (
        <Button size={size} variant={variant} {...props}>
          {!spinner ? label : <CircularProgress size={25} />}
        </Button>
      ) : (
        <Skeleton variant='rectangular' animation='wave' width={80} height={40} />
      )}
    </>
  );
}

export default ButtonForm;
