import { useState, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// ** Import to Hook
import { IUpdatePriceArticle } from '../../../models/common';
import { IServiceFormQuote } from '../../../models/services';
import { IArticlesFormQuote } from '../../../models/orders';
import { initialValues, schemeValidation } from '../utils/schemeValidation.util';

interface Params {
  addArticle: (values: IArticlesFormQuote) => void;
  updateArticle: (values: IArticlesFormQuote, number: number | undefined) => void;
  values: IServiceFormQuote | undefined;
}

export const useQuoteService = ({ addArticle, updateArticle, values }: Params) => {
  /* ---- Hooks ---- */
  const [open, setOpen] = useState<boolean>(false);
  const [openChangePrice, setOpenChangePrice] = useState<boolean>(false);

  const methods = useForm<IServiceFormQuote>({
    defaultValues: values || initialValues,
    resolver: yupResolver(schemeValidation),
  });

  const unitPrice = useWatch({
    name: 'unitPrice',
    control: methods.control,
  });

  const quantity = useWatch({
    name: 'quantity',
    control: methods.control,
  });

  useEffect(() => {
    if (quantity) methods.setValue('totalPrice', quantity * unitPrice);
  }, [quantity, unitPrice, methods]);

  /* ---- Functions ---- */
  const handleClickOpen = () => setOpen(true);

  const handleOpenChangePrice = () => setOpenChangePrice(true);

  const handleClose = () => setOpen(false);

  const handleCloseChangePrice = () => setOpenChangePrice(false);

  const handleService = (values: IServiceFormQuote) => {
    methods.reset(values);
    handleClose();
  };

  const handleUpdate = (data: IUpdatePriceArticle) => {
    methods.setValue('unitPrice', data.price);
    methods.setValue('beforeChange', data.beforePrice);
    methods.setValue('afterChange', data.afterPrice);
    methods.setValue('changePrice', data.changePrice);
    methods.setValue('reason', data.reason);
    handleCloseChangePrice();
  };

  const onSubmit = (data: IServiceFormQuote) => {
    const article: IArticlesFormQuote = {
      id: data.id,
      article: 'service',
      type: data.type,
      name: data.name,
      description: data.description,
      typeOfLoad: data.typeOfLoad,
      quantity: data.quantity || 0,
      unitPrice: data.unitPrice,
      tax: data.tax || false,
      safe: data.safe || false,
      subTotal: data.totalPrice || 0,
      changePrice: data.changePrice,
      afterChange: data.afterChange,
      beforeChange: data.beforeChange,
      reason: data.reason,
    };

    if (!values) {
      addArticle(article);
    } else {
      updateArticle(article, values.number);
    }
  };

  return [
    open,
    openChangePrice,
    unitPrice,
    methods,
    handleService,
    handleClickOpen,
    handleClose,
    handleOpenChangePrice,
    handleCloseChangePrice,
    handleUpdate,
    onSubmit,
  ] as const;
};
