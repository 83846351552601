// ** MUI Components
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const RightWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: 450,
  },
}));

export const BoxWrapper = styled(Box)(({ theme }) => ({
  margin: '2rem 1rem',
  [theme.breakpoints.up('sm')]: {
    margin: '2rem auto',
    maxWidth: 450,
  },
}));

export const TypographyStyled = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: { marginTop: theme.spacing(8) },
}));
