import { FormProvider } from 'react-hook-form';

// ** Mui
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';

// ** Icons
import PlusCircle from 'mdi-material-ui/PlusCircle';
import Lock from 'mdi-material-ui/Lock';

// ** Component
import TextForm from '../../components/TextForm/TextForm.component';
import ButtonForm from '../../components/ButtonForm/ButtonForm.component';
import CheckboxForm from '../../components/CheckboxForm/CheckboxForm.component';
import IconButton from '../../components/IconButton/IconButton.component';

// ** Views
import ProductsModal from '../ProductsModal/ProductsModal.view';
import ChangePrice from '../ChangePrice/ChangePrice.component';

// ** Import to Component
import { IProductFormQuote } from '../../models/products';
import { IArticlesFormQuote } from '../../models/orders';
import { useQuoteProduct } from './hooks/useQuoteProduct.hook';

interface Props {
  detailsView: () => void;
  addArticle: (values: IArticlesFormQuote) => void;
  updateArticle: (values: IArticlesFormQuote, number: number | undefined) => void;
  values: IProductFormQuote | undefined;
}

function QuoteProduct({ detailsView, addArticle, updateArticle, values }: Props) {
  const [
    open,
    openChangePrice,
    unitPrice,
    methods,
    handleProduct,
    handleClickOpen,
    handleClose,
    handleOpenChangePrice,
    handleCloseChangePrice,
    handleUpdate,
    onSubmit,
  ] = useQuoteProduct({
    addArticle,
    updateArticle,
    values,
  });

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} sm={10} sx={{ mx: 'auto' }}>
              <Card>
                <CardHeader title='Product Details' titleTypographyProps={{ variant: 'h6' }} />
                <Divider sx={{ margin: 0 }} />
                <CardContent>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <IconButton icon={PlusCircle} onClick={handleClickOpen} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextForm fullWidth name='type' label='Product Type' disabled />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextForm fullWidth name='name' label='Product' disabled />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextForm fullWidth name='description' label='Description' disabled />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextForm fullWidth name='quantity' label='Quantity' type='number' />
                    </Grid>
                    <Grid item xs={12} sm={3} sx={{ display: 'flex', flexDirection: 'row' }}>
                      <TextForm fullWidth name='unitPrice' label='Unit Price' type='number' disabled />
                      <IconButton icon={Lock} sx={{ ml: 1 }} onClick={handleOpenChangePrice} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextForm fullWidth name='height' label='Height' type='number' />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextForm fullWidth name='hight' label='Hight' type='number' />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextForm fullWidth name='width' label='Width' type='number' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CheckboxForm name='safe' label='Apply Safe' />
                      <CheckboxForm name='tax' label='Apply Tax' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextForm fullWidth name='content' label='Content' />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <TextForm name='totalPrice' label='Total Price' type='number' />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider sx={{ margin: 0 }} />
                <CardActions sx={{ padding: '1rem' }}>
                  <ButtonForm label={!values ? 'Add' : 'Update'} type='submit' sx={{ mr: 2 }} />
                  <ButtonForm size='large' color='info' label='Reviewing' onClick={detailsView} />
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <ProductsModal dialogTitle='Products List' open={open} handleClose={handleClose} handleProduct={handleProduct} />
      <ChangePrice
        dialogTitle='Change Price'
        open={openChangePrice}
        handleClose={handleCloseChangePrice}
        handleUpdate={handleUpdate}
        currentPrice={unitPrice}
      />
    </>
  );
}

export default QuoteProduct;
