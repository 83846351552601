import * as Yup from 'yup';

export const initialValues = {
  address: '',
  city: '',
  confirmPassword: '',
  country: '',
  email: '',
  name: '',
  password: '',
  phone: '',
  position: '',
  postalCode: '',
  state: '',
  user: '',
};

export const schemeValidation = Yup.object({
  address: Yup.string().required('You must enter a Address'),
  city: Yup.string().required('You must enter a City'),
  country: Yup.string().required('You must enter a Country'),
  email: Yup.string().email('You must enter a valid email').required('You must enter an email'),
  name: Yup.string().required('You must enter a Name'),
  phone: Yup.string().required('You must enter a Phone'),
  position: Yup.string().required('You must enter a Position'),
  postalCode: Yup.string().required('You must enter a Postal Code'),
  state: Yup.string().required('You must enter a State'),
  user: Yup.string().required('You must enter a User'),
  password: Yup.string().required('You must enter a Password'),
  confirmPassword: Yup.string()
    .required('You must enter a Password')
    .oneOf([Yup.ref('password'), null], 'Passwords have to match'),
});
