import React from 'react';

// ** MUI Imports
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';

import UserDropdown from '../Dropdown/UserDropdown.component';
import LanguageDropdown from '../Dropdown/LanguageDropdown.component';

// ** Styled
import { AppBar, Toolbar } from './styled.componet';

interface Props {
  open: boolean;
  handleDrawerToggle: () => void;
}

function LayoutAppBar({ handleDrawerToggle, open }: Props) {
  return (
    <AppBar color='default' className='layout-navbar'>
      <Toolbar className='navbar-content-container'>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant='h6' noWrap component='div' sx={{ flexGrow: 1 }}>
          Section Title
        </Typography>
        <LanguageDropdown />
        <UserDropdown />
      </Toolbar>
    </AppBar>
  );
}

export default LayoutAppBar;
