import { useState, useEffect } from 'react';

// ** MUI Imports
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';

// ** Components
import ButtonForm from '../../components/ButtonForm/ButtonForm.component';

// ** Import to Component
import { defaultColumns } from './quoteDetailsColumns';
import { IQuoteArticlesTable } from '../../models/orders';

interface Props {
  resetStep: () => void;
  backStep: () => void;
  productView: (value: boolean) => void;
  serviceView: (value: boolean) => void;
  articles: IQuoteArticlesTable[];
  viewUpdateArticle: (values: IQuoteArticlesTable) => void;
  deleteArticle: (value: number) => void;
  generateQuote: () => void;
  update?: boolean;
}

function QuoteDetails({
  backStep,
  resetStep,
  productView,
  serviceView,
  articles,
  viewUpdateArticle,
  deleteArticle,
  generateQuote,
  update,
}: Props) {
  const [total, setTotal] = useState<number>(0);

  const columns = defaultColumns(viewUpdateArticle, deleteArticle);

  useEffect(() => {
    const priceTotal = articles.reduce((prev, next) => prev + (next.total || 0), 0);
    setTotal(priceTotal);
  }, [articles]);

  return (
    <Grid
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Grid item xs={12} sx={{ my: 2 }}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem',
            mb: 1,
          }}
        >
          <CardContent>
            <Typography sx={{ mt: 2, mb: 1 }} variant='h5'>
              {update ? 'Update Quote' : 'New quote'}
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <ButtonForm label='Product' sx={{ m: 1, width: '8rem' }} color='info' onClick={() => productView(false)} />

            <ButtonForm label='Service' sx={{ m: 1, width: '8rem' }} color='info' onClick={() => serviceView(false)} />

            <ButtonForm label='Reset' sx={{ m: 1, width: '8rem' }} color='error' onClick={resetStep} />

            <ButtonForm label='Back' sx={{ m: 1, width: '8rem' }} variant='outlined' onClick={backStep} />
          </CardActions>
        </Card>
        <Card sx={{ padding: '1rem', mt: 1 }}>
          <DataGrid
            autoHeight
            rows={articles}
            // loading={loading}
            // rowCount={queryOptions.rowCount}
            // checkboxSelection
            // pageSize={queryOptions.pageSize}
            // page={queryOptions.page}
            disableSelectionOnClick
            columns={columns}
            rowsPerPageOptions={[20, 35, 50]}
            // paginationMode="server"
            // onPageChange={(newPage: number) => {
            //   setPage(newPage);
            // }}
            // onPageSizeChange={(newPageSize: number) => {
            //   setPage(0);
            //   setPageSize(newPageSize);
            // }}
            // disableColumnFilter
          />
          <CardContent
            sx={{
              width: '10rem',
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 'auto',
            }}
          >
            <TextField label='Trip Load' type='number' sx={{ mb: 1 }} value={0} />
            <TextField label='Total' type='number' sx={{ mt: 1 }} value={total} />
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ButtonForm label={update ? 'update' : 'Generate'} sx={{ mr: 2 }} onClick={() => generateQuote()} />
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

export default QuoteDetails;
