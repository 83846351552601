import { gql, useQuery } from '@apollo/client';
import { usePagination } from '../common';
import { IListServices, IServiceFilters } from '../../models/services';
import { IAccountList } from '../../models/accounts';
import { IItemsSelectArray } from '../../models/common';

interface IFindServices {
  services: {
    rows: IListServices[];
    total: number;
  };
  accounts: IAccountList[];
}

const FIND_SERVICES = gql`
  query getServices($take: Int!, $page: Int!, $accountId: Int, $type: String!, $name: String!, $description: String!) {
    services(take: $take, page: $page, accountId: $accountId, type: $type, name: $name, description: $description) {
      rows {
        id
        accountId
        type
        name
        description
        cost
        price
        maxWeight
      }
      total
    }
    accounts {
      id
      name
    }
  }
`;

export const useFindService = (filters: IServiceFilters, setItems: (values: IItemsSelectArray[]) => void) => {
  const [queryOptions, setPage, setPageSize, setRowCount] = usePagination();

  const { data, loading, error } = useQuery<IFindServices>(FIND_SERVICES, {
    variables: {
      page: queryOptions.page,
      take: queryOptions.pageSize,
      accountId: filters.accountId,
      type: filters.type,
      name: filters.name,
      description: filters.description,
    },
    onCompleted(data) {
      setRowCount(data.services.total);

      const accounts: IItemsSelectArray[] = [];
      data.accounts.map(account => accounts.push({ value: account.id, label: account.name }));
      setItems(accounts);
    },
  });

  return [data, loading, error, queryOptions, setPage, setPageSize] as const;
};
