import * as Yup from 'yup';

export const initialValues = {
  changePrice: false,
  afterPrice: undefined,
  beforePrice: undefined,
  price: undefined,
  reason: '',
};

export const schemeValidation = Yup.object({
  price: Yup.number().required('You must enter a Price'),
  reason: Yup.string().required('You must enter a Reason'),
});
