// ** Imports
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// ** MUI Imports
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';

interface Props extends StandardTextFieldProps {
  name: string;
  loading?: boolean;
}

function TextForm({ name, loading, ...props }: Props) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      {!loading ? (
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <TextField
              {...props}
              {...field}
              error={!!errors[name]}
              helperText={errors[name] ? errors[name]?.message : ''}
            />
          )}
        />
      ) : (
        <Skeleton variant='rectangular' height={60} />
      )}
    </>
  );
}

export default TextForm;
