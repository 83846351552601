// ** MUI Imports
import Divider from '@mui/material/Divider';

// ** Styled
import { ListSubheader, TypographyHeaderText } from './styled.component';

// ** Interface
import { Item } from '../../interface/items.interface';

interface Props {
  item: Item;
}

function NavSectionTitle({ item }: Props) {
  return (
    <div>
      <ListSubheader>
        <Divider
          textAlign='left'
          sx={{
            m: 0,
            width: '100%',
            lineHeight: 'normal',
            textTransform: 'uppercase',
            '&:before, &:after': { top: 7, transform: 'none' },
            '& .MuiDivider-wrapper': {
              px: 2.5,
              fontSize: '0.75rem',
              letterSpacing: '0.21px',
            },
          }}
        >
          <TypographyHeaderText noWrap>{item.sectionTitle}</TypographyHeaderText>
        </Divider>
      </ListSubheader>
    </div>
  );
}

export default NavSectionTitle;
