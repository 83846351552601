import { gql, useLazyQuery, ApolloError } from '@apollo/client';
import { IUserUpdateForm, IUserById } from '../../models/users';

interface Query {
  user: IUserById;
}

const ALL_USERS = gql`
  query getUserById($id: Int!) {
    user(id: $id) {
      id
      name
      email
      address
      phone
      countryAlphacode
      postalCode
      user
      state
      city
      status
    }
  }
`;

export const useFindUserById = (
  setValues: (value: IUserUpdateForm) => void,
): [findUser: (value: number) => void, loading: boolean, error: ApolloError | undefined] => {
  const [user, { loading, error }] = useLazyQuery<Query>(ALL_USERS, {
    onCompleted: data => {
      const { user } = data;
      setValues({
        ...user,
        country: user.countryAlphacode,
        position: '',
      });
    },
  });

  const findUser = (id: number) => {
    user({ variables: { id } });
  };

  return [findUser, loading, error];
};
