// ** Icon imports
import LockOutline from 'mdi-material-ui/LockOutline';
import HomeOutline from 'mdi-material-ui/HomeOutline';
import AccountOutline from 'mdi-material-ui/AccountOutline';
import FileDocumentOutline from 'mdi-material-ui/FileDocumentOutline';
import AccountGroup from 'mdi-material-ui/AccountGroup';

const navigation = () => {
  return [
    {
      title: 'Home',
      icon: HomeOutline,
      path: '/',
    },
    {
      sectionTitle: 'Apps & Pages',
    },
    {
      title: 'Orders',
      icon: FileDocumentOutline,
      children: [
        {
          title: 'Add',
          path: '/orders/add',
        },
        {
          title: 'Quote List',
          path: '/orders/quote',
        },
      ],
    },
    {
      title: 'User',
      icon: AccountOutline,
      children: [
        {
          title: 'List',
          path: '/user/list',
          icon: AccountGroup,
        },
        {
          title: 'New',
          path: '/user/form',
        },
      ],
    },
    {
      title: 'Roles & Permissions',
      icon: LockOutline,
      children: [
        {
          title: 'Roles',
          path: '/roles',
        },
      ],
    },
  ];
};

export default navigation;
