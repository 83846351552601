// ** React Imports
import { useState, Fragment } from 'react';

// ** MUI Imports
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';

// ** Icons Imports
import CircleOutline from 'mdi-material-ui/CircleOutline';

// ** Component
import NavLink from '../NavLink/NavLink.component';

// ** Styled
import { MenuGroupToggleRightIcon, MenuItemTextWrapper } from './styled.component';

// ** Interface
import { Item } from '../../interface/items.interface';

interface Props {
  item: Item;
}

function NavGroup({ item }: Props) {
  const [open, setOpen] = useState(false);
  const IconTag = item.icon === undefined ? CircleOutline : item.icon;
  const children = item.children;

  const handleClick = () => {
    setOpen(prev => !prev);
  };
  return (
    <div>
      <Fragment>
        <ListItem
          disablePadding
          className='nav-group'
          sx={{ mt: 0, px: '0 !important', flexDirection: 'column' }}
          onClick={handleClick}
        >
          <ListItemButton
            sx={{
              width: '100%',
              borderTopRightRadius: 100,
              borderBottomRightRadius: 100,
              transition: 'padding .25s ease-in-out',
            }}
          >
            <ListItemIcon
              sx={{
                color: 'text.primary',
                transition: 'margin .25s ease-in-out',
              }}
            >
              <IconTag />
            </ListItemIcon>
            <MenuItemTextWrapper>
              <Typography>{item.title}</Typography>
              <Box className='menu-item-meta' sx={{ ml: 0.8, display: 'flex', alignItems: 'center' }}>
                <MenuGroupToggleRightIcon sx={{ ...(open ? { transform: 'rotate(90deg)' } : {}) }} />
              </Box>
            </MenuItemTextWrapper>
          </ListItemButton>
          <Collapse
            component='ul'
            in={open}
            onClick={e => e.stopPropagation()}
            sx={{
              pl: 0,
              width: '100%',
              my: 0,
              transition: 'all .25s ease-in-out',
            }}
          >
            <List component='div' disablePadding>
              {children?.map((child: any, index) => (
                <NavLink key={index} item={child} group />
              ))}
            </List>
          </Collapse>
        </ListItem>
      </Fragment>
    </div>
  );
}

export default NavGroup;
