import { useMemo, useState } from 'react';

export type TPagination = [
  queryOptions: {
    page: number;
    pageSize: number;
    rowCount: number;
  },
  setPage: (value: number) => void,
  setPageSize: (value: number) => void,
  setRowCount: (value: number) => void,
];

export const usePagination = (): TPagination => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);

  const queryOptions = useMemo(
    () => ({
      page,
      pageSize,
      rowCount,
    }),
    [page, pageSize, rowCount],
  );
  return [queryOptions, setPage, setPageSize, setRowCount];
};
