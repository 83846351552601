// ** Imports
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// ** Mui
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';

interface Props extends StandardTextFieldProps {
  name: string;
  label: string;
}

function TimePicker({ name, label, ...props }: Props) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiTimePicker
            label={label}
            value={field.value || null}
            onChange={field.onChange}
            renderInput={params => (
              <TextField
                {...params}
                {...field}
                {...props}
                placeholder='hora'
                error={!!errors[name]}
                helperText={errors[name] ? errors[name]?.message : ''}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
}

export default TimePicker;
