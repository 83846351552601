import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// MUI Imports
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
// Mdi
import CircleOutline from 'mdi-material-ui/CircleOutline';
// Styled
import { MenuItemTextMetaWrapper, MenuNavLink } from './styled.component';
// Interface
import { Item } from '../../interface/items.interface';
// Import to Component
import { NavigationContext } from '../Navigation/Navigation.component';

interface Props {
  item: Item;
  group?: boolean;
}

function NavLink({ item, group }: Props) {
  const IconTag = item.icon === undefined ? CircleOutline : item.icon;
  const navigate = useNavigate();
  const context = useContext(NavigationContext);

  const handleClick = () => {
    navigate(item.path || '/');
    context.closeDrawerMobile();
  };

  return (
    <div>
      <ListItem disablePadding className='nav-link' sx={{ mt: 0, px: '0 !important' }} onClick={handleClick}>
        <MenuNavLink>
          <ListItemIcon
            sx={{
              color: 'text.primary',
              transition: 'margin .25s ease-in-out',
            }}
          >
            <IconTag
              sx={{
                ...(group && {
                  ml: '0.4rem',
                  ...(item.icon ? { fontSize: '0.875rem' } : { fontSize: '0.825rem' }),
                }),
              }}
            />
          </ListItemIcon>

          <MenuItemTextMetaWrapper>
            <Typography>{item.title}</Typography>
          </MenuItemTextMetaWrapper>
        </MenuNavLink>
      </ListItem>
    </div>
  );
}

export default NavLink;
