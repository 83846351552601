import * as Yup from 'yup';

export const initialValues = {
  activities: [],
};

export const schemeValidation = Yup.object({
  activities: Yup.array().of(
    Yup.object().shape({
      details: Yup.string().required('You must enter a Details'),
      start: Yup.date().required('You must enter a Start Date'),
      end: Yup.date().required('You must enter a End Date'),
    }),
  ),
});
