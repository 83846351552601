import { FormProvider } from 'react-hook-form';

// ** Mui
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';

// ** Component
import SelectForm from '../../components/SelectForm/SelectForm.component';
import ButtonForm from '../../components/ButtonForm/ButtonForm.component';

// ** Import to Component
import { IQuoteSelectForm } from '../../models/orders';
import { IAccountFormQuote } from '../../models/accounts';
import { useQuoteSelect } from './hooks/useQuoteSelect.hook';

const itemsTypeService = [
  {
    value: 'L',
    label: 'Local Operations',
  },
  {
    value: 'E',
    label: 'Intra State Operations',
  },
  {
    value: 'I',
    label: 'Inter State Operations',
  },
  {
    value: 'CL',
    label: 'Local Crating Service',
  },
  {
    value: 'CI',
    label: 'Inter State Crating Services',
  },
];

const itemsBillTo = [
  {
    value: 'account',
    label: 'Account',
  },
  {
    value: 'customer',
    label: 'Customer',
  },
];

interface Props {
  nextStep: () => void;
  backStep: () => void;
  setIsAccount: (value: boolean) => void;
  setIsOpsLocals: (value: boolean) => void;
  values: IQuoteSelectForm | undefined;
  setValues: (values: IQuoteSelectForm) => void;
  setAccount: (values: IAccountFormQuote) => void;
}

function QuoteSelectGroup({ backStep, nextStep, setIsAccount, setIsOpsLocals, values, setValues, setAccount }: Props) {
  const [methods, itemsAccount, loading, error, onSubmit] = useQuoteSelect({
    nextStep,
    setIsAccount,
    setIsOpsLocals,
    values,
    setValues,
    setAccount,
  });

  if (error) return <div>Error</div>;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} sm={10} sx={{ mx: 'auto' }}>
            <Card sx={{ m: 2 }}>
              <CardHeader title='Add Quote' titleTypographyProps={{ variant: 'h5' }} />
              <Divider sx={{ margin: 0 }} />
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={4}>
                    <SelectForm name='accountId' label='Account' items={itemsAccount || []} loading={loading} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectForm name='billTo' label='Bill to' items={itemsBillTo} loading={loading} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectForm
                      name='typeOfService'
                      label='Type of Service'
                      items={itemsTypeService}
                      loading={loading}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider sx={{ margin: 0 }} />
              <CardActions sx={{ justifyContent: 'space-between', padding: '1rem' }}>
                <ButtonForm
                  size='large'
                  color='secondary'
                  variant='outlined'
                  onClick={backStep}
                  label='Back'
                  loading={loading}
                />
                <ButtonForm label='Next' type='submit' sx={{ mr: 2 }} loading={loading} />
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default QuoteSelectGroup;
