// ** Mui
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

// ** Component
import TextForm from '../../components/TextForm/TextForm.component';
import SelectForm from '../../components/SelectForm/SelectForm.component';

interface Props {
  add: boolean;
  title: string;
}

const countries = [
  {
    value: 'usa',
    label: 'United States of America',
  },
  {
    value: 'vzl',
    label: 'Venezuela',
  },
];

const positions = [
  {
    value: 1,
    label: 'Manager',
  },
  {
    value: 2,
    label: 'Administrator',
  },
];

const UserForm = ({ add, title }: Props) => {
  return (
    <>
      <CardHeader title={title} titleTypographyProps={{ variant: 'h6' }} />
      <Divider sx={{ margin: 0 }} />
      <CardContent>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant='body2' sx={{ fontWeight: 600 }}>
              1. Account Details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextForm fullWidth name='user' label='Username' />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextForm fullWidth name='email' label='Email' />
          </Grid>
          {add && (
            <>
              <Grid item xs={12} sm={6}>
                <TextForm fullWidth name='password' label='Password' type='password' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextForm fullWidth name='confirmPassword' label='Confirm Password' type='password' />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Divider sx={{ marginBottom: 0 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2' sx={{ fontWeight: 600 }}>
              2. Personal Info
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextForm fullWidth name='name' label='Name' />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextForm fullWidth name='phone' label='Phone' />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectForm name='position' label='Position' items={positions} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextForm fullWidth name='address' label='Address' />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectForm name='country' label='Country' items={countries} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextForm fullWidth name='postalCode' label='Postal Code' />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextForm fullWidth name='state' label='State' />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextForm fullWidth name='city' label='City' />
          </Grid>
        </Grid>
      </CardContent>
      <Divider sx={{ margin: 0 }} />
      <CardActions sx={{ padding: '1rem' }}>
        <Button size='large' type='submit' sx={{ mr: 2 }} variant='contained'>
          Submit
        </Button>
        <Button size='large' color='secondary' variant='outlined'>
          Cancel
        </Button>
      </CardActions>
    </>
  );
};

export default UserForm;
