import { IMutationActivity } from '../../models/orderActivity';
import { IActivitiesFormQuote } from '../../models/orders';

export function activitiesAdapter({ activities }: IActivitiesFormQuote) {
  const array: IMutationActivity[] = activities.map(activity => {
    const adaptedActivity: IMutationActivity = {
      id: activity.id,
      activityId: parseInt(activity.details),
      start: activity.start,
      end: activity.end,
    };
    return adaptedActivity;
  });
  return array;
}
