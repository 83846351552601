import {
  IQuoteSelectForm,
  INewQuote,
  IDetailsFormQuote,
  IQuoteArticlesTable,
  IAddressFormQuote,
} from '../../models/orders';
import { IAccountFormQuote } from '../../models/accounts';
import { ICustomerFormQuote } from '../../models/customer';
import { arrayAddition } from '../../utils/arrayAddition.util';

export function newQuoteAdapter(
  dataSelects: IQuoteSelectForm | undefined,
  dataAccount: IAccountFormQuote | undefined,
  dataCustomer: ICustomerFormQuote | undefined,
  dataAddress: IAddressFormQuote | undefined,
  dataDetails: IDetailsFormQuote | undefined,
  articles: IQuoteArticlesTable[],
  isAccount: boolean,
  userId: number,
) {
  if (!dataSelects) return;
  if (!dataCustomer) return;
  if (!dataCustomer.id) return;
  if (!dataAddress) return;
  if (!dataDetails) return;

  const newQuote: INewQuote = {
    accountId: parseInt(dataSelects.accountId),
    customerId: dataCustomer.id,
    userId,
    invoiceTo: isAccount ? 1 : 2,
    typeLocation: dataSelects.typeOfService,
    price: dataDetails.estimatedCost,
    estimatedValue: dataDetails.estimatedValue,
    typePayment: parseInt(dataDetails.typePayment),
    noArt: articles.length,
    subtotal: arrayAddition(articles, 'subTotal'),
    taxation: arrayAddition(articles, 'taxation'),
    insurance: arrayAddition(articles, 'insurance'),
    tripCharge: 0,
    total: arrayAddition(articles, 'total'),
    numberOrder: isAccount ? dataAccount?.numberOrder : dataCustomer.numberOrder,
    gbl: isAccount ? dataAccount?.gbl : dataCustomer.gbl,
    workTicket: isAccount ? dataAccount?.workTicker : dataCustomer.workTicker,
    ref: isAccount ? dataAccount?.reference : dataCustomer.reference,
    notes: isAccount ? dataAccount?.note : dataCustomer.note,
    cubFoot: dataDetails.cubicFoot,
    distWeight: dataDetails.weightDistribution,
    weight: dataDetails.estimatedWeight,
    miles: dataDetails.estimatedMiles,
    workDate: dataDetails.estimatedDate,
    workBegins: dataDetails.startTimer,
    workEnds: dataDetails.endTime,
    address: dataAddress.address,
    countryAlphacode: dataAddress.country,
    postalCode: dataAddress.postalCode,
    city: dataAddress.city,
    state: dataAddress.state,
  };

  return newQuote;
}
