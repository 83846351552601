import * as Yup from 'yup';

export const initialValues = {
  address: '',
  country: '',
  postalCode: '',
  state: '',
  city: '',
};

export const schemeValidation = Yup.object({
  address: Yup.string().required('You must enter a Address'),
  country: Yup.string().required('You must enter a Country'),
  postalCode: Yup.string().required('You must enter a Postal Code'),
  state: Yup.string().required('You must enter a State'),
  city: Yup.string().required('You must enter a City'),
});
