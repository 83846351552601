import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// ** Mui
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';

// ** Icons
import MapMarkerRadius from 'mdi-material-ui/MapMarkerRadius';

// ** Component
import TextForm from '../../components/TextForm/TextForm.component';
import ButtonForm from '../../components/ButtonForm/ButtonForm.component';
import SelectForm from '../../components/SelectForm/SelectForm.component';
import IconButton from '../../components/IconButton/IconButton.component';

// ** Import to Component
import { initialValues, schemeValidation } from './utils/schemeValidation.util';
import { IItemsSelectArray } from '../../models/common';
import { IAddressFormQuote } from '../../models/orders';
import { ICustomerFormQuote } from '../../models/customer';

interface Props {
  nextStep: () => void;
  backStep: () => void;
  countries: IItemsSelectArray[];
  values: IAddressFormQuote | undefined;
  setValues: (value: IAddressFormQuote) => void;
  customer: ICustomerFormQuote | undefined;
}

function QuoteAddress({ backStep, nextStep, countries, values, setValues, customer }: Props) {
  const methods = useForm<IAddressFormQuote>({
    defaultValues: values || customer || initialValues,
    resolver: yupResolver(schemeValidation),
  });

  const onSubmit = (data: IAddressFormQuote) => {
    setValues(data);
    nextStep();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} sm={10} sx={{ mx: 'auto' }}>
            <Card>
              <CardHeader title={'Address'} titleTypographyProps={{ variant: 'h6' }} />
              <Divider sx={{ margin: 0 }} />
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton icon={MapMarkerRadius} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextForm fullWidth name='address' label='Address' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectForm name='country' label='Country' items={countries} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='postalCode' label='Postal Code' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='state' label='State' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextForm fullWidth name='city' label='City' />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider sx={{ margin: 0 }} />
              <CardActions sx={{ justifyContent: 'space-between', padding: '1rem' }}>
                <ButtonForm size='large' color='secondary' variant='outlined' onClick={backStep} label='Back' />
                <ButtonForm label='Next' type='submit' sx={{ mr: 2 }} />
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default QuoteAddress;
