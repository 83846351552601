import { Controller, useFormContext } from 'react-hook-form';

// ** MUI Imports
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props extends CheckboxProps {
  name: string;
  label: string;
}

function CheckboxForm({ name, label, size = 'small', ...props }: Props) {
  const {
    control,
    // formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={false}
      render={({ field }) => (
        <FormControlLabel control={<Checkbox size={size} {...field} {...props} />} label={label} />
      )}
    />
  );
}

export default CheckboxForm;
