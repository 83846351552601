// MUI Imports
import Grid from '@mui/material/Grid';

interface Props {
  title: JSX.Element | string;
  subTitle?: JSX.Element | string;
}

function PageHeader({ title, subTitle }: Props) {
  return (
    <Grid item xs={12}>
      {title}
      {subTitle || null}
    </Grid>
  );
}

export default PageHeader;
