// ** MUI Imports
import Grid from '@mui/material/Grid';

// ** Components
import TextForm from '../../components/TextForm/TextForm.component';
import SelectForm from '../../components/SelectForm/SelectForm.component';

// ** Import to Components
import { IItemsSelectArray } from '../../models/common';

interface Props {
  items: IItemsSelectArray[];
}

function ProductFilters({ items }: Props) {
  return (
    <Grid container>
      <Grid item xs={10} sx={{ mx: 'auto', my: 2 }}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={3}>
            <SelectForm name='accountId' label='Account' items={items} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextForm name='type' label='Type' />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextForm name='name' label='Name' />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextForm name='description' label='Description' />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProductFilters;
