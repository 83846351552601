import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const MainBoxWrapper = (drawerWidth: number) => {
  return styled(Box, {
    shouldForwardProp: prop => prop !== 'open',
  })<{
    open?: boolean;
  }>(({ theme, open }) => ({
    flexGrow: 1,
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      // backgroundColor: theme.palette.primary.main,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: `${drawerWidth}px`,
      }),
    },
  }));
};

export const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  display: 'flex',
  margin: '1rem 0',
  padding: theme.spacing(2, 4),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  [theme.breakpoints.up('xl')]: {
    padding: theme.spacing(2, 20),
  },
}));

export const LayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex',
});
