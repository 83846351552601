import { gql, useQuery } from '@apollo/client';
import { usePagination } from '../common';
import { IListProducts, IProductFilters } from '../../models/products';
import { IAccountList } from '../../models/accounts';
import { IItemsSelectArray } from '../../models/common';

interface FindProducts {
  products: {
    rows: IListProducts[];
    total: number;
  };
  accounts: IAccountList[];
}

const FIND_PRODUCTS = gql`
  query getProducts($take: Int!, $page: Int!, $accountId: Int, $type: String!, $name: String!, $description: String!) {
    products(take: $take, page: $page, accountId: $accountId, type: $type, name: $name, description: $description) {
      rows {
        id
        accountId
        type
        name
        description
        quantity
        cost
        price
        high
        height
        width
      }
      total
    }
    accounts {
      id
      name
    }
  }
`;

export const useFindProducts = (filters: IProductFilters, setItems: (values: IItemsSelectArray[]) => void) => {
  const [queryOptions, setPage, setPageSize, setRowCount] = usePagination();

  const { data, loading, error } = useQuery<FindProducts>(FIND_PRODUCTS, {
    variables: {
      page: queryOptions.page,
      take: queryOptions.pageSize,
      accountId: filters.accountId,
      type: filters.type,
      name: filters.name,
      description: filters.description,
    },
    onCompleted(data) {
      setRowCount(data.products.total);

      const accounts: IItemsSelectArray[] = [];
      data.accounts.map(account => accounts.push({ value: account.id, label: account.name }));
      setItems(accounts);
    },
  });

  return [data, loading, error, queryOptions, setPage, setPageSize] as const;
};
