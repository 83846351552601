import { Navigate, Outlet } from 'react-router-dom';
import MainWrapper from '../layouts/MainWrapper/MainWrapper.layout';

function PrivateRoute({ session }: any) {
  return session ? (
    <>
      <div>
        <MainWrapper>
          <Outlet />
        </MainWrapper>
      </div>
    </>
  ) : (
    <Navigate to='/login' />
  );
}

export default PrivateRoute;
