import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// ** Import to Component
import { useFindAccounts } from '../../../hooks/accounts';
import { IAccountFormQuote } from '../../../models/accounts';
import { IQuoteSelectForm } from '../../../models/orders';
import { schemeValidation, initialValues } from '../utils/schemeValidation.util';

interface ItemsAccounts {
  value: number;
  label: string;
}

interface Params {
  nextStep: () => void;
  setIsAccount: (value: boolean) => void;
  setIsOpsLocals: (value: boolean) => void;
  values: IQuoteSelectForm | undefined;
  setValues: (values: IQuoteSelectForm) => void;
  setAccount: (values: IAccountFormQuote) => void;
}

export const useQuoteSelect = ({ nextStep, setIsAccount, values, setValues, setIsOpsLocals, setAccount }: Params) => {
  const [itemsAccount, setItemsAccounts] = useState<ItemsAccounts[]>();
  const [data, loading, error] = useFindAccounts();

  const methods = useForm<IQuoteSelectForm>({
    defaultValues: values || initialValues,
    resolver: yupResolver(schemeValidation),
  });

  const billTo = useWatch({ name: 'billTo', control: methods.control });
  const typeService = useWatch({
    name: 'typeOfService',
    control: methods.control,
  });

  useEffect(() => {
    if (billTo === 'account') {
      setIsAccount(true);
    } else {
      setIsAccount(false);
    }

    if (typeService === 'L') {
      setIsOpsLocals(true);
    } else {
      setIsOpsLocals(false);
    }

    if (data) {
      const accounts: ItemsAccounts[] = [];
      data.accounts.map(account => accounts.push({ value: account.id, label: account.name }));
      setItemsAccounts(accounts);
    }
  }, [billTo, setIsAccount, data, typeService, setIsOpsLocals]);

  const onSubmit = (dataForm: IQuoteSelectForm) => {
    if (data) {
      const { accounts } = data;
      const filterAccount = accounts.filter(account => account.id === parseInt(dataForm.accountId))[0];
      setAccount({
        country: filterAccount.countryAlphacode,
        ...filterAccount,
      });
      setValues(dataForm);
      nextStep();
    }
  };

  return [methods, itemsAccount, loading, error, onSubmit] as const;
};
