import { FormLogin } from '../../models/login/form-login.model';
import { UserSession } from '../../models/state/user-session.model';
import { config } from '../../utils/config.util';

export const useLogin = (): [
  request: (data: FormLogin) => Promise<{
    dataLogin: UserSession | undefined;
    error: any;
  }>,
] => {
  const request = async (data: FormLogin) => {
    try {
      const req = await fetch(`${config.url}/login`, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      const response: UserSession = await req.json();
      return { dataLogin: response, error: undefined };
    } catch (error) {
      return { dataLogin: undefined, error };
    }
  };

  return [request];
};
