import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

// ** Import to Component
import PrivateRoutes from './PrivateRoutes.route';
import PublicRoutes from './PublicRoutes.route';
import { routes } from '../utils/routesList.util';
import { IState } from '../models/state';

function AppRouter() {
  const session = useSelector((state: IState) => state.userSession);

  return (
    <>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes session={session} />}>
            {routes.map(
              (route, index) =>
                route.private && route.access && <Route key={index} path={route.path} element={route.element} />,
            )}
          </Route>

          <Route element={<PublicRoutes session={session} />}>
            {routes.map(
              (route, index) => !route.private && <Route key={index} path={route.path} element={route.element} />,
            )}
          </Route>

          <Route path='*' element={<div>NotFoundPage</div>} />
        </Routes>
      </Router>
    </>
  );
}

export default AppRouter;
