import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// ** Import to Component
import { initialValues, schemeValidation } from '../utils/schemeValidation.util';
import { IItemsAutocompleteArray } from '../../../models/common';
import { ICustomerFormQuote } from '../../../models/customer';
import { useCreateCustomer, useUpdateCustomer, useFindCustomerByName } from '../../../hooks/customer';

interface Props {
  nextStep: () => void;
  accountId: number | undefined;
  values: ICustomerFormQuote | undefined;
  setValues: (value: ICustomerFormQuote) => void;
}

export const useQuoteCustomer = ({ nextStep, accountId, values, setValues }: Props) => {
  const [items, setItems] = useState<IItemsAutocompleteArray<ICustomerFormQuote>[]>([]);
  const [customerName, setCustomerName] = useState<string>('');
  const [customer, setCustomer] = useState<ICustomerFormQuote>();
  const [isUpdate, setIsUpdate] = useState<boolean>(values !== undefined);
  const [render, setRender] = useState<boolean>(false);

  const [findCustomer, , loading] = useFindCustomerByName();
  const [createCustomer, dataCreate, loadingCreate] = useCreateCustomer();
  const [updateCustomer, dataUpdate, loadingUpdate] = useUpdateCustomer();

  const methods = useForm<ICustomerFormQuote>({
    defaultValues: values || initialValues,
    resolver: yupResolver(schemeValidation),
  });

  useEffect(() => {
    const account = accountId || 0;

    findCustomer(customerName, account, setItems);
  }, [accountId, customerName, values, methods]);

  useEffect(() => {
    if (customer) {
      methods.reset(customer);
      setIsUpdate(true);
    }
  }, [customer, methods]);

  useEffect(() => {
    if ((dataCreate && !loadingCreate) || (dataUpdate && !loadingUpdate)) {
      setRender(false);
    }
  }, [dataCreate, loadingCreate, dataUpdate, loadingUpdate]);

  const handleCancel = () => {
    setRender(false);
    methods.reset(customer);
  };

  const handleClick = () => {
    setRender(true);
  };

  const handleAction = (data: ICustomerFormQuote) => {
    if (!accountId) return;

    if (isUpdate) {
      updateCustomer(data, accountId);
    } else {
      createCustomer(data, accountId);
    }
  };

  const onSubmit = (data: ICustomerFormQuote) => {
    setValues(data);
    nextStep();
  };

  return [
    methods,
    items,
    setCustomerName,
    setCustomer,
    loading,
    onSubmit,
    isUpdate,
    render,
    handleClick,
    handleCancel,
    handleAction,
  ] as const;
};
