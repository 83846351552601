import * as Yup from 'yup';

export const initialValues = {
  name: '',
  address: '',
  country: '',
  postalCode: '',
  state: '',
  city: '',
  phone: '',
  email: '',
  numberOrder: '',
  gbl: '',
  workTicker: '',
  reference: '',
  note: '',
};

export const schemeValidation = Yup.object({
  name: Yup.string().required('You must enter a Name'),
  address: Yup.string().required('You must enter a Address'),
  country: Yup.string().required('You must enter a Country'),
  postalCode: Yup.string().required('You must enter a Postal Code'),
  state: Yup.string().required('You must enter a State'),
  city: Yup.string().required('You must enter a City'),
  phone: Yup.string().required('You must enter a Phone'),
  email: Yup.string().required('You must enter a Email'),
  numberOrder: Yup.string().optional(),
  gbl: Yup.string().optional(),
  workTicker: Yup.string().optional(),
  reference: Yup.string().optional(),
  note: Yup.string().optional(),
});
