import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';

// ** Components
import AppRouter from './routes/AppRouter.route';
import ToasterMessage from './components/ToasterMessage/ToasterMessage.component';
import SubscriptionsWrapper from './layouts/SubscriptionsWrapper/SubscriptionsWrapper.layout';

// ** Import to Component
import { customTheme } from './utils/customTheme.util';
import { config } from './utils/config.util';

const App = () => {
  const session = useSelector((state: any) => state.userSession);
  const httpLink = createHttpLink({
    uri: `${config.url}/graphql`,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${config.ws}/graphql`,
    }),
  );

  const authLink = setContext((_, { headers }) => {
    const token = session?.accessToken;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    authLink.concat(httpLink),
  );

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: splitLink,
  });

  return (
    <ApolloProvider client={client}>
      <SubscriptionsWrapper>
        <div className='App'>
          <ThemeProvider theme={customTheme}>
            <ToasterMessage />
            <AppRouter />
          </ThemeProvider>
        </div>
      </SubscriptionsWrapper>
    </ApolloProvider>
  );
};

export { App };
