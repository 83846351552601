import * as Yup from 'yup';

export const initialValues = {
  accountId: '',
  billTo: '',
  typeOfService: undefined,
};

export const schemeValidation = Yup.object({
  accountId: Yup.string().required('You must select a Account'),
  billTo: Yup.string().required('You must select Account or Customer'),
  typeOfService: Yup.string().required('You must select a Service'),
});
