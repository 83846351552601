import { gql, useQuery, ApolloError } from '@apollo/client';
import { usePagination } from '../common';
import { UsersList } from '../../models/users/users-list.model';

interface AllUsers {
  users: {
    rows: UsersList[];
    total: number;
  };
}

const ALL_USERS = gql`
  query getUsers($page: Int!, $take: Int!) {
    users(page: $page, take: $take) {
      rows {
        id
        name
        email
        address
        phone
        countryAlphacode
        postalCode
        user
        state
        city
        status
      }
      total
    }
  }
`;

export const useAllUsers = (): [
  data: AllUsers | undefined,
  loading: boolean,
  error: ApolloError | undefined,
  queryOptions: {
    page: number;
    pageSize: number;
    rowCount: number;
  },
  setPage: (value: number) => void,
  setPageSize: (value: number) => void,
] => {
  const [queryOptions, setPage, setPageSize, setRowCount] = usePagination();

  const { data, loading, error } = useQuery<AllUsers>(ALL_USERS, {
    variables: { page: queryOptions.page, take: queryOptions.pageSize },
    onCompleted(data) {
      setRowCount(data.users.total);
    },
  });

  return [data, loading, error, queryOptions, setPage, setPageSize];
};
