import React from 'react';

// ** Components
import NavSectionTitle from '../NavSectionTitle/NavSectionTitle.component';
import NavGroup from '../NavGroup/NavGroup.component';
import NavLink from '../NavLink/NavLink.component';

// ** Interface
import { Item } from '../../interface/items.interface';

interface Props {
  items: Item[];
}

const resolveNavItemComponent = (item: Item) => {
  if (item.sectionTitle) return NavSectionTitle;
  if (item.children) return NavGroup;

  return NavLink;
};

function NavItem({ items }: Props) {
  const RenderMenuItems = items?.map((item: Item, index: number) => {
    const TagName = resolveNavItemComponent(item);

    return <TagName key={index} item={item} />;
  });

  return <React.Fragment>{RenderMenuItems}</React.Fragment>;
}

export default NavItem;
