import { FormProvider } from 'react-hook-form';

// ** MUI Imports
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';

// ** Mui
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';

// ** Components
import QuoteFilters from '../../views/QuoteFilters/QuoteFilters.view';
import DialogModal from '../../components/DialogModal/DialogModal.component';
import ButtonForm from '../../components/ButtonForm/ButtonForm.component';

// Import to Component
import { defaultColumns } from './quoteColumns';
import { useQuoteList } from './hooks/useQuoteList.hook';

function QuoteList() {
  const columns = defaultColumns();

  const [methods, data, loading, error, queryOptions, setPage, setPageSize, open, setOpen, setRow, handleEdit] =
    useQuoteList();

  const buttons = [
    { label: 'Edit', onClick: () => handleEdit() },
    {
      label: 'To Print',
      onClick: () => {
        return null;
      },
    },
    {
      label: 'Athorize',
      onClick: () => {
        return null;
      },
    },
    {
      label: 'Send',
      onClick: () => {
        return null;
      },
    },
    {
      label: 'Cancel',
      onClick: () => {
        return null;
      },
    },
  ];

  if (error) return <div>Error</div>;

  return (
    <>
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Grid item xs={12}>
          <Card>
            <FormProvider {...methods}>
              <form>
                <QuoteFilters />
              </form>
            </FormProvider>
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ my: 2 }}>
          <Card>
            <DataGrid
              autoHeight
              rows={data?.ordersByType?.rows || []}
              loading={loading}
              rowCount={queryOptions.rowCount}
              checkboxSelection
              pageSize={queryOptions.pageSize}
              page={queryOptions.page}
              disableSelectionOnClick
              columns={columns}
              rowsPerPageOptions={[20, 35, 50]}
              paginationMode='server'
              onPageChange={(newPage: number) => {
                setPage(newPage);
              }}
              onPageSizeChange={(newPageSize: number) => {
                setPage(0);
                setPageSize(newPageSize);
              }}
              pagination
              disableColumnFilter
              onRowClick={({ row }) => {
                setOpen(true);
                setRow(row);
              }}
            />
          </Card>
        </Grid>
      </Grid>
      <DialogModal dialogTitle='' open={open} handleClose={() => setOpen(false)}>
        <Typography variant='h5' sx={{ textAlign: 'center' }}>
          Indicate the action you want to perform
        </Typography>
        <DialogActions
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            my: 2,
          }}
        >
          {buttons.map((button, index) => (
            <ButtonForm
              label={button.label}
              sx={{ my: 1, width: '10rem', ml: index === 0 ? 1 : 0 }}
              key={index}
              onClick={button.onClick}
            />
          ))}
        </DialogActions>
      </DialogModal>
    </>
  );
}

export default QuoteList;
