import { gql, useQuery } from '@apollo/client';
import { IAccountList, IAccountFormQuote } from '../../models/accounts';

interface IFindAccount {
  account: IAccountList;
}

const FIND_ACCOUNT = gql`
  query getAccountById($id: Int!) {
    account(id: $id) {
      id
      name
      address
      countryAlphacode
      postalCode
      state
      city
      phone
      email
      minAmount
    }
  }
`;

export const useFindAccountById = (id: number, setValues: (values: IAccountFormQuote) => void) => {
  const { data, loading, error } = useQuery<IFindAccount>(FIND_ACCOUNT, {
    variables: {
      id,
    },
    onCompleted: data => {
      const { account } = data;
      setValues({ country: account.countryAlphacode, note: '', ...account });
    },
  });

  return [data, loading, error] as const;
};
