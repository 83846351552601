import { gql, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { ICustomerFormQuote } from '../../models/customer';
import { IState } from '../../models/state';
import { alertError, alertSuccess } from '../../components/ToasterMessage/ToasterMessage.component';

interface IMutation extends ICustomerFormQuote {
  userId: number;
}

const CREATE_CUSTOMER = gql`
  mutation createCustomer(
    $userId: Int!
    $accountId: Int!
    $name: String!
    $email: String!
    $address: String!
    $phone: String!
    $country: String!
    $postalCode: String!
    $state: String!
    $city: String!
  ) {
    createCustomer(
      createCustomerInput: {
        userId: $userId
        accountId: $accountId
        name: $name
        email: $email
        address: $address
        phone: $phone
        countryAlphacode: $country
        postalCode: $postalCode
        state: $state
        city: $city
      }
    ) {
      userId
      name
      email
      address
      phone
      countryAlphacode
      postalCode
      user
      state
      city
    }
  }
`;

export const useCreateCustomer = () => {
  const session = useSelector((state: IState) => state.userSession);

  const [create, { data, loading, error }] = useMutation<IMutation>(CREATE_CUSTOMER);

  const createCustomer = (customer: ICustomerFormQuote, accountId: number) => {
    create({
      variables: { userId: session.id, accountId, ...customer },
      onCompleted: () => {
        alertSuccess('Customer Created');
      },
      onError: () => {
        alertError('An error has ocurred');
      },
    });
  };

  return [createCustomer, data, loading, error] as const;
};
