import { gql, useMutation } from '@apollo/client';
import { IMutationActivity } from '../../models/orderActivity';
import { IPlannedDatesFormQuote, IArticlesAdapter, INewQuote as IUpdateQuote } from '../../models/orders';

const UPDATE_QUOTE = gql`
  mutation (
    $plannedDates: [UpdateOrderPlannedDate!]!
    $activities: [UpdateOrderActivity!]!
    $details: [UpdateDetail!]!
    $id: Int!
    $accountId: Int!
    $userId: Int!
    $customerId: Int!
    $addressId: Int
    $ref: String
    $gbl: String
    $notes: String
    $workTicket: String
    $numberOrder: String
    $invoiceTo: Int!
    $instructionsEspecials: String
    $typeLocation: String!
    $price: Float!
    $estimatedValue: Float
    $workDate: DateTime!
    $workBegins: DateTime
    $workEnds: DateTime
    $cubFoot: Int
    $weight: Int
    $miles: Int
    $typePayment: Int!
    $warehouse: String
    $distWeight: Int
    $adjustment: Float
    $noArt: Int!
    $subtotal: Float!
    $taxation: Float!
    $insurance: Float!
    $tripCharge: Float!
    $total: Float!
    $address: String
    $countryAlphacode: String
    $postalCode: String
    $state: String
    $city: String
  ) {
    updateOrder(
      updateOrderInput: {
        plannedDates: $plannedDates
        activities: $activities
        details: $details
        id: $id
        accountId: $accountId
        userId: $userId
        customerId: $customerId
        addressId: $addressId
        ref: $ref
        gbl: $gbl
        notes: $notes
        workTicket: $workTicket
        numberOrder: $numberOrder
        invoiceTo: $invoiceTo
        instructionsEspecials: $instructionsEspecials
        typeLocation: $typeLocation
        price: $price
        estimatedValue: $estimatedValue
        workDate: $workDate
        workBegins: $workBegins
        workEnds: $workEnds
        cubFoot: $cubFoot
        weight: $weight
        miles: $miles
        typePayment: $typePayment
        warehouse: $warehouse
        distWeight: $distWeight
        adjustment: $adjustment
        noArt: $noArt
        subtotal: $subtotal
        taxation: $taxation
        insurance: $insurance
        tripCharge: $tripCharge
        total: $total
        address: $address
        countryAlphacode: $countryAlphacode
        postalCode: $postalCode
        state: $state
        city: $city
      }
    ) {
      id
    }
  }
`;

export const useUpdateQuote = () => {
  const [update, { data, loading, error }] = useMutation(UPDATE_QUOTE);

  const updateQuote = (
    id: number,
    dataQuote: IUpdateQuote,
    plannedDates: IPlannedDatesFormQuote,
    activities: IMutationActivity[],
    articles: IArticlesAdapter[],
  ) => {
    update({
      variables: {
        plannedDates: plannedDates.plannedDates,
        activities,
        details: articles,
        id,
        ...dataQuote,
      },
    });
  };

  return [updateQuote, data, loading, error] as const;
};
