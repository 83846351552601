import { IQuoteArticlesTable, IArticlesAdapter } from '../../models/orders';

export function articlesApdater(articles: IQuoteArticlesTable[], userId: number) {
  const array: IArticlesAdapter[] = articles.map(article => {
    const adaptedArticle: IArticlesAdapter = {
      id: article.rowId,
      articleId: article.id,
      content: article.content,
      description: article.description,
      footCubic: 0,
      height: article.height,
      high: article.hight,
      insurance: article.insurance,
      loadType: article.typeOfLoad,
      price: article.subTotal,
      quantity: article.quantity,
      subtotal: article.subTotal,
      taxation: article.taxation,
      total: article.total,
      type: article.article === 'product' ? '1' : '2',
      unitaryPrice: article.unitPrice,
      weight: article.weight,
      reWeight: article.reWeight,
      width: article.width,
      beforePrice: article.beforeChange,
      afterPrice: article.afterChange,
      reason: article.reason,
      userId,
    };

    return adaptedArticle;
  });

  return array;
}
