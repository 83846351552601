import { gql, useSubscription, useApolloClient } from '@apollo/client';
import { FIND_QUOTES, FindOrders } from '../orders/useFindOrderByType.hook';
import { IQuoteById, IQuoteList } from '../../models/orders';
import { today, getDateSubtractingDays } from '../../utils/getDates.util';

interface OrderSubscription {
  orderMutation: IQuoteById;
}

const ORDER_SUBSCRIPTION = gql`
  subscription quoteSubscription {
    orderMutation {
      plannedDates {
        id
        orderId
        details
        start
        end
      }
      activities {
        id
        orderId
        activityId
        start
        end
      }
      details {
        id
        orderId
        articleId
        type
        description
        quantity
        unitaryPrice
        price
        loadType
        height
        high
        width
        measureHeight
        measureHigh
        measureWidth
        footCubic
        weightVol
        weight
        reWeight
        insurance
        taxation
        subtotal
        total
        content
      }
      customer {
        id
        name
        address
        countryAlphacode
        postalCode
        state
        city
        phone
        email
      }
      id
      identifier
      accountId
      userId
      customerId
      status
      ref
      gbl
      equipment
      notes
      workTicket
      numberOrder
      invoiceTo
      instructionsEspecials
      type
      typeLocation
      price
      estimatedValue
      workDate
      workBegins
      workEnds
      cubFoot
      weight
      miles
      typePayment
      warehouse
      distWeight
      adjustment
      noArt
      subtotal
      taxation
      insurance
      tripCharge
      total
      address
      countryAlphacode
      postalCode
      state
      city
    }
  }
`;

export const useOrderSubscription = () => {
  const client = useApolloClient();
  useSubscription<OrderSubscription>(ORDER_SUBSCRIPTION, {
    onSubscriptionData: ({ subscriptionData }) => {
      const { data } = subscriptionData;

      if (!data) return null;

      const variables = {
        page: 0,
        take: 10,
        type: data.orderMutation.type,
        status: data.orderMutation.status,
        account: '',
        customer: '',
        startDate: getDateSubtractingDays(30),
        endDate: today,
      };

      const dataInStore: FindOrders<IQuoteList> | null = client.readQuery({
        query: FIND_QUOTES,
        variables,
      });

      if (dataInStore && data) {
        const { orderMutation } = data;
        const { ordersByType } = dataInStore;
        const { rows, total } = ordersByType;

        const newRows: IQuoteList[] = [
          {
            id: orderMutation.id,
            identifier: orderMutation.identifier,
            type: orderMutation.type,
            subtotal: orderMutation.subtotal,
            total: orderMutation.total,
          },
          ...rows,
        ];

        client.writeQuery({ query: FIND_QUOTES, variables, data: { ordersByType: { rows: newRows, total } } });
      }
    },
  });
};
