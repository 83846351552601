import { gql, useMutation, ApolloError } from '@apollo/client';
import { useSelector } from 'react-redux';
import { IUserUpdateForm } from '../../models/users/user-form.model';
import { IState } from '../../models/state';

interface IMutation extends IUserUpdateForm {
  userId: number;
}

interface IParams {
  alertSuccess: (message: string) => void;
  alertError: (message: string) => void;
}

const UPDATE_USER = gql`
  mutation updateUser(
    $id: Int!
    $userId: Int!
    $name: String!
    $email: String!
    $address: String!
    $phone: String!
    $country: String!
    $postalCode: String!
    $user: String!
    $state: String!
    $city: String!
  ) {
    updateUser(
      updateUserInput: {
        id: $id
        userId: $userId
        name: $name
        email: $email
        address: $address
        phone: $phone
        countryAlphacode: $country
        postalCode: $postalCode
        user: $user
        state: $state
        city: $city
      }
    ) {
      id
      userId
      name
      email
      address
      phone
      countryAlphacode
      postalCode
      user
      state
      city
    }
  }
`;

export const useUpdateUser = ({
  alertSuccess,
  alertError,
}: IParams): [
  request: (value: IUserUpdateForm) => void,
  data: IMutation | null | undefined,
  loading: boolean,
  error: ApolloError | undefined,
] => {
  const [updateUser, { data, loading, error }] = useMutation<IMutation>(UPDATE_USER);
  const session = useSelector((state: IState) => state.userSession);

  const request = (data: IUserUpdateForm) => {
    updateUser({
      variables: {
        ...data,
        userId: session.id,
      },
      onCompleted: () => {
        alertSuccess('User update');
      },
      onError: () => {
        alertError('An error has ocurred');
      },
    });
  };
  return [request, data, loading, error];
};
