// ** Mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

// ** Views
import QuoteSelectGroup from '../../views/QuoteSelectGroup/QuoteSelectGroup.view';
import QuoteFormDetails from '../../views/QuoteFormDetails/QuoteFormDetails.view';
import QuoteAccount from '../../views/QuoteAccount/QuoteAccount.view';
import QuoteCustomer from '../../views/QuoteCustomer/QuoteCustomer.view';
import QuoteAddress from '../../views/QuoteAddress/QuoteAddress.view';
import QuotePlannedDates from '../../views/QuotePlannedDates/QuotePlannedDates.view';
import QuoteActivities from '../../views/QuoteActivities/QuoteActivities.component';
import QuoteProduct from '../../views/QuoteProduct/QuoteProduct.view';
import QuoteService from '../../views/QuoteService/QuoteService.view';
import QuoteDetails from '../../views/QuoteDetails/QuoteDetails.view';

// ** Components
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog.component';

// ** Import to Component
import { useAddQuote } from './hooks/useAddQuote.hook';

function AddOrder() {
  const [componentStates, formStates, functions] = useAddQuote();

  const { steps, activeStep, countries, isAccount, isOpsLocals, renderStepper, view, confirmDialog, setConfirmDialog } =
    componentStates;

  const { selects, account, customer, address, formDetails, plannedDates, activities, product, service, articles } =
    formStates;

  const {
    handleNext,
    handleBack,
    alertReset,
    productView,
    serviceView,
    detailsView,
    addArticle,
    viewUpdateArticle,
    updateArticle,
    deleteArticle,
    generateQuote,
  } = functions;

  const getStepContent = (view: number) => {
    switch (view) {
      case 0:
        return (
          <QuoteSelectGroup
            nextStep={handleNext}
            backStep={handleBack}
            setIsAccount={isAccount.setValues}
            setIsOpsLocals={isOpsLocals.setValues}
            values={selects.values}
            setValues={selects.setValues}
            setAccount={account.setValues}
          />
        );

      case 1:
        return (
          <QuoteAccount
            nextStep={handleNext}
            backStep={handleBack}
            countries={countries}
            isOpsLocals={isOpsLocals.values}
            values={account.values}
            setValues={account.setValues}
          />
        );

      case 2:
        return (
          <QuoteCustomer
            nextStep={handleNext}
            backStep={handleBack}
            isAccount={isAccount.values}
            isOpsLocals={isOpsLocals.values}
            accountId={selects.values && parseInt(selects.values.accountId)}
            values={customer.values}
            setValues={customer.setValues}
            countries={countries}
          />
        );

      case 3:
        return (
          <QuoteAddress
            nextStep={handleNext}
            backStep={handleBack}
            countries={countries}
            values={address.values}
            setValues={address.setValues}
            customer={customer.values}
          />
        );

      case 4:
        return (
          <QuoteFormDetails
            nextStep={handleNext}
            backStep={handleBack}
            values={formDetails.values}
            setValues={formDetails.setValues}
          />
        );

      case 5:
        return (
          <QuotePlannedDates
            nextStep={handleNext}
            backStep={handleBack}
            values={plannedDates.values}
            setValues={plannedDates.setValues}
          />
        );

      case 6:
        return (
          <QuoteActivities
            nextStep={handleNext}
            backStep={handleBack}
            values={activities.values}
            setValues={activities.setValues}
          />
        );

      case 7:
        return (
          <QuoteDetails
            resetStep={alertReset}
            backStep={handleBack}
            productView={productView}
            serviceView={serviceView}
            articles={articles.values}
            viewUpdateArticle={viewUpdateArticle}
            deleteArticle={deleteArticle}
            generateQuote={generateQuote}
          />
        );

      case 8:
        return (
          <QuoteProduct
            detailsView={detailsView}
            addArticle={addArticle}
            updateArticle={updateArticle}
            values={product.values}
          />
        );

      case 9:
        return (
          <QuoteService
            detailsView={detailsView}
            addArticle={addArticle}
            updateArticle={updateArticle}
            values={service.values}
          />
        );

      default:
        return 'Unknown Step';
    }
  };

  return (
    <>
      <Box style={{ width: '100%' }}>
        <Grid container>
          <Grid item xs={12} sm={10} sx={{ mx: 'auto' }}>
            {renderStepper && (
              <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 2 }}>
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel>{step.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
            <>{getStepContent(view)}</>
          </Grid>
        </Grid>
      </Box>
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
}

export default AddOrder;
