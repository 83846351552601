import { FormProvider } from 'react-hook-form';

// ** Mui
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';

// ** Icons
import SquareEditOutline from 'mdi-material-ui/SquareEditOutline';
import PlusCircle from 'mdi-material-ui/PlusCircle';

// ** Component
import TextForm from '../../components/TextForm/TextForm.component';
import ButtonForm from '../../components/ButtonForm/ButtonForm.component';
import AutocompleteText from '../../components/AutocompleteText/AutocompleteText.component';
import IconButton from '../../components/IconButton/IconButton.component';
import SelectForm from '../../components/SelectForm/SelectForm.component';

// ** Import to Component
import { ICustomerFormQuote } from '../../models/customer';
import { IItemsSelectArray } from '../../models/common';
import { useQuoteCustomer } from './hooks/useQuoteCustomer.hook';

interface Props {
  nextStep: () => void;
  backStep: () => void;
  isAccount: boolean;
  isOpsLocals: boolean;
  accountId: number | undefined;
  values: ICustomerFormQuote | undefined;
  setValues: (value: ICustomerFormQuote) => void;
  countries: IItemsSelectArray[];
}

function QuoteCustomer({ backStep, nextStep, isAccount, isOpsLocals, accountId, values, setValues, countries }: Props) {
  const [
    methods,
    items,
    setCustomerName,
    setCustomer,
    loading,
    onSubmit,
    isUpdate,
    render,
    handleClick,
    handleCancel,
    handleAction,
  ] = useQuoteCustomer({
    nextStep,
    accountId,
    values,
    setValues,
  });

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} sm={10} sx={{ mx: 'auto' }}>
              <Card>
                <CardHeader
                  title={isOpsLocals ? 'Destiny' : 'Customer Data'}
                  titleTypographyProps={{ variant: 'h6' }}
                />
                <Divider sx={{ margin: 0 }} />
                <CardContent>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <IconButton icon={isUpdate ? SquareEditOutline : PlusCircle} onClick={handleClick} />
                    </Grid>
                    <Grid item xs={12}>
                      {render ? (
                        <TextForm fullWidth name='name' label='Name' />
                      ) : (
                        <AutocompleteText<ICustomerFormQuote>
                          fullWidth
                          name='name'
                          label='Name'
                          options={items}
                          spinner={loading}
                          setValue={setCustomerName}
                          onChangeItem={setCustomer}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextForm fullWidth name='phone' label='Phone' disabled={!render} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextForm fullWidth name='email' label='Email' disabled={!render} />
                    </Grid>
                    {render && (
                      <>
                        <Grid item xs={12}>
                          <TextForm fullWidth name='address' label='Address' />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SelectForm name='country' label='Country' items={countries} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextForm fullWidth name='postalCode' label='Postal Code' />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextForm fullWidth name='state' label='State' />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextForm fullWidth name='city' label='City' />
                        </Grid>
                      </>
                    )}
                    {!isAccount && (
                      <>
                        {isOpsLocals ? (
                          <>
                            <Grid item xs={12} sm={6}>
                              <TextForm fullWidth name='numberOrder' label='Number Order' />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextForm fullWidth name='workTicket' label='Work Ticket' />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12} sm={6}>
                              <TextForm fullWidth name='reference' label='Reference' />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextForm fullWidth name='notes' label='Notes' />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    <Grid item xs={12}>
                      <TextForm fullWidth name='specialInstructions' label='Special Instructions' />
                    </Grid>
                  </Grid>
                </CardContent>
                {render && (
                  <>
                    <Divider sx={{ margin: 0 }} />
                    <CardActions
                      sx={{
                        justifyContent: 'space-between',
                        padding: '1rem',
                      }}
                    >
                      <ButtonForm
                        size='large'
                        color='secondary'
                        variant='outlined'
                        onClick={handleCancel}
                        label='Cancel'
                      />
                      <ButtonForm
                        label={isUpdate ? 'Update' : 'Create'}
                        sx={{ mr: 2 }}
                        onClick={methods.handleSubmit(handleAction)}
                      />
                    </CardActions>
                  </>
                )}
                <Divider sx={{ margin: 0 }} />
                <CardActions sx={{ justifyContent: 'space-between', padding: '1rem' }}>
                  <ButtonForm
                    size='large'
                    color='secondary'
                    variant='outlined'
                    onClick={backStep}
                    label='Back'
                    disabled={render}
                  />
                  <ButtonForm label='Next' type='submit' sx={{ mr: 2 }} disabled={render} />
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}

export default QuoteCustomer;
