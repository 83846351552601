import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// ** MUI Imports
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';

// Import to Component
import TableHeader from '../../components/TableHeader/TableHeader.component';
import { alertSuccess, alertError } from '../../components/ToasterMessage/ToasterMessage.component';
import { defaultColumns } from './usersColumns';
import { useAllUsers, useDeleteUser } from '../../hooks/users';

const useParamDelete = {
  alertSuccess: (message: string) => alertSuccess(message),
  alertError: (message: string) => alertError(message),
};

function UserList() {
  const [request] = useDeleteUser(useParamDelete);

  const navigate = useNavigate();

  const [data, loading, error, queryOptions, setPage, setPageSize] = useAllUsers();

  const [valueFilter, setValueFilter] = useState('');

  const handleFilter = (value: string) => {
    setValueFilter(value);
  };

  const editUser = (userId: number) => {
    navigate(`/user/form/${userId}`);
  };

  const deleteUser = (id: number) => {
    request(id);
  };

  const columns = defaultColumns(editUser, deleteUser);

  if (error) return <Box>Error</Box>;

  return (
    <Box style={{ width: '100%' }}>
      <Grid item xs={12}>
        <Card>
          <TableHeader
            handleFilter={handleFilter}
            onClick={() => navigate('/user/form')}
            value={valueFilter}
            title='Add User'
          />
          <DataGrid
            autoHeight
            rows={data?.users?.rows || []}
            loading={loading}
            rowCount={queryOptions.rowCount}
            checkboxSelection
            pageSize={queryOptions.pageSize}
            page={queryOptions.page}
            disableSelectionOnClick
            columns={columns}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginationMode='server'
            onPageChange={(newPage: number) => {
              setPage(newPage);
            }}
            onPageSizeChange={(newPageSize: number) => {
              setPage(0);
              setPageSize(newPageSize);
            }}
            pagination
          />
        </Card>
      </Grid>
    </Box>
  );
}

export default UserList;
