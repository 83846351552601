import { gql, useQuery } from '@apollo/client';
import { IListActivities } from '../../models/activities';
import { IItemsSelectArray } from '../../models/common';

interface IFindActivities {
  activities: IListActivities[];
}

const FIND_ACTIVITIES = gql`
  query getActivities {
    activities {
      id
      name
    }
  }
`;

export const useFindActivities = (setValues: (values: IItemsSelectArray[]) => void) => {
  const { data, loading, error } = useQuery<IFindActivities>(FIND_ACTIVITIES, {
    onCompleted: data => {
      const array: IItemsSelectArray[] = [];
      data.activities.map(activity => array.push({ value: activity.id, label: activity.name }));
      setValues(array);
    },
  });

  return [data, loading, error] as const;
};
