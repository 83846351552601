import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserSession } from '../../models/state/user-session.model';

const storage = localStorage.getItem('user-session');

const userSlice = createSlice({
  name: 'userSession',
  initialState: storage ? JSON.parse(storage) : null,
  reducers: {
    addSession: (state, action: PayloadAction<UserSession>) => {
      localStorage.setItem('user-session', JSON.stringify(action.payload));
      return action.payload;
    },
    deleteSession: () => {
      localStorage.removeItem('user-session');
      return null;
    },
  },
});

export const { addSession, deleteSession } = userSlice.actions;
export default userSlice.reducer;
