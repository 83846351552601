import { gql, useLazyQuery } from '@apollo/client';
import { IQuoteById } from '../../models/orders';

interface IFindQuote {
  findQuote: IQuoteById;
}

const FIND_ORDER = gql`
  query getOrderById($id: Int!) {
    findQuote(id: $id) {
      plannedDates {
        id
        details
        start
        end
      }
      activities {
        id
        activityId
        start
        end
      }
      details {
        id
        orderId
        articleId
        name
        typeArticle
        content
        description
        footCubic
        height
        high
        insurance
        loadType
        price
        quantity
        subtotal
        taxation
        total
        type
        unitaryPrice
        weight
        reWeight
        width
      }
      customer {
        id
        name
        address
        countryAlphacode
        postalCode
        state
        city
        phone
        email
      }
      id
      accountId
      customerId
      invoiceTo
      typeLocation
      price
      estimatedValue
      typePayment
      noArt
      subtotal
      taxation
      insurance
      tripCharge
      total
      numberOrder
      gbl
      workTicket
      ref
      notes
      cubFoot
      distWeight
      weight
      miles
      workDate
      workBegins
      workEnds
      address
      countryAlphacode
      postalCode
      city
      state
    }
  }
`;

export const useFindQuoteById = () => {
  const [find, { data, loading, error }] = useLazyQuery<IFindQuote>(FIND_ORDER);

  const findOrder = (id: number) => {
    find({ variables: { id } });
  };

  return [findOrder, data, loading, error] as const;
};
