import { useState, ReactNode } from 'react';

// ** Components
import AppBar from './components/AppBar/AppBar.component';
import Navigation from './components/Navigation/Navigation.component';

// ** Mui
import CssBaseline from '@mui/material/CssBaseline';

// ** Styled
import { MainBoxWrapper, ContentWrapper, LayoutWrapper } from './styled.component';

interface Props {
  window?: () => Window;
  children?: ReactNode;
}

const drawerWidth = 280;

const MainContentWrapper = MainBoxWrapper(drawerWidth);

function MainWrapper({ children, window }: Props) {
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <LayoutWrapper>
      <CssBaseline />
      <Navigation drawerWidth={drawerWidth} open={open} handleDrawerToggle={handleDrawerToggle} container={container} />
      <MainContentWrapper open={open}>
        <AppBar handleDrawerToggle={handleDrawerToggle} open={open} />
        <ContentWrapper>{children}</ContentWrapper>
      </MainContentWrapper>
    </LayoutWrapper>
  );
}

export default MainWrapper;
