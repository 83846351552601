import Login from '../pages/Login/Login.page';
import Home from '../pages/Home/Home.page';
import UserList from '../pages/UserList/UserList.page';
import AddUser from '../pages/AddUser/AddUser.page';
import AddOrder from '../pages/AddOrder/AddOrder.page';
import Roles from '../pages/Roles/Roles.page';
import UpdateUser from '../pages/UpdateUser/UpdateUser.component';
import QuoteList from '../pages/QuoteList/QuoteList.page';
import UpdateQuote from '../pages/UpdateQuote/UpdateQuote.page';

export const routes = [
  {
    path: '/login',
    element: <Login />,
    private: false,
    access: true,
  },
  {
    path: '/',
    element: <Home />,
    private: true,
    access: true,
  },
  {
    path: '/user/list',
    element: <UserList />,
    private: true,
    access: true,
  },
  {
    path: '/user/form',
    element: <AddUser />,
    private: true,
    access: true,
  },
  {
    path: '/user/form/:id',
    element: <UpdateUser />,
    private: true,
    access: true,
  },
  {
    path: '/orders/quote',
    element: <QuoteList />,
    private: true,
    access: true,
  },
  {
    path: '/orders/add',
    element: <AddOrder />,
    private: true,
    access: true,
  },
  {
    path: '/orders/update/:id',
    element: <UpdateQuote />,
    private: true,
    access: true,
  },
  {
    path: '/roles',
    element: <Roles />,
    private: true,
    access: true,
  },
];
