// ** MUI Imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Import to Component
import { IQuoteList } from '../../models/orders';

interface RenderCell {
  row: IQuoteList;
}

export const defaultColumns = () => {
  return [
    {
      flex: 0.25,
      minWidth: 230,
      field: 'identifier',
      headerName: 'Identifier',
      renderCell: ({ row }: RenderCell) => {
        const { identifier } = row;

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Typography
              noWrap
              component='a'
              variant='body2'
              sx={{
                fontWeight: 600,
                color: 'text.primary',
                textDecoration: 'none',
              }}
            >
              {identifier}
            </Typography>
          </Box>
        );
      },
    },
    {
      flex: 0.25,
      field: 'subtotal',
      minWidth: 250,
      headerName: 'Subtotal',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography noWrap variant='body2'>
            {row.subtotal}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 140,
      field: 'total',
      headerName: 'Total',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.total}
          </Typography>
        );
      },
    },
  ];
};
