import { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// ** Mui
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';

interface Props extends RadioGroupProps {
  name: string;
  children?: ReactNode;
}

function RadioButtonGroup({ name, children, ...props }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <RadioGroup {...field} {...props}>
          {children}
        </RadioGroup>
      )}
    />
  );
}

export default RadioButtonGroup;
