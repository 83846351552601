// ** MUI Imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import AvatarGroup from '@mui/material/AvatarGroup';
import CardContent from '@mui/material/CardContent';

interface CardData {
  totalUsers: number;
  title: string;
  avatars: string[];
}

interface Props {
  cardData: CardData[];
  handleClickOpen: () => void;
  setDialogTitle: (value: string) => void;
}

function RenderCards({ cardData, handleClickOpen, setDialogTitle }: Props) {
  return (
    <>
      {cardData.map((item: CardData, index: number) => (
        <Grid item xs={12} sm={6} lg={4} key={index}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  mb: 3,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant='body2'>Total {item.totalUsers} users</Typography>
                <AvatarGroup
                  max={4}
                  sx={{
                    '& .MuiAvatarGroup-avatar': { fontSize: '.875rem' },
                    '& .MuiAvatar-root, & .MuiAvatarGroup-avatar': {
                      width: 32,
                      height: 32,
                    },
                  }}
                >
                  {item.avatars.map((img: string, index: number) => (
                    <Avatar key={index} alt={item.title} src={`/images/avatars/${img}`} />
                  ))}
                </AvatarGroup>
              </Box>
              <Box>
                <Typography variant='h6'>{item.title}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant='body2'
                  sx={{ color: 'primary.main', cursor: 'pointer' }}
                  onClick={() => {
                    handleClickOpen();
                    setDialogTitle('Edit');
                  }}
                >
                  Edit Role
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  );
}

export default RenderCards;
