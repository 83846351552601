// ** Imports
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// ** Mui
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Skeleton from '@mui/material/Skeleton';

interface Item {
  value: string | number;
  label: string;
}

interface Props {
  name: string;
  label: string;
  items: Item[];
  loading?: boolean;
  disabled?: boolean;
}

function SelectForm({ name, label, items, loading, disabled }: Props) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      {!loading ? (
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <>
                <Select label={label} defaultValue='' error={!!errors[name]} disabled={disabled} {...field}>
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  {items.map((item: Item, index: number) => (
                    <MenuItem value={item.value} key={index}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors[name] && <FormHelperText sx={{ color: 'error.main' }}>{errors[name]?.message}</FormHelperText>}
              </>
            )}
          />
        </FormControl>
      ) : (
        <Skeleton variant='rectangular' animation='wave' height={60} />
      )}
    </>
  );
}

export default SelectForm;
