// Mui
import Box from '@mui/material/Box';
// Components
import FormLogin from './components/FormLogin/FormLogin.component';

function Login() {
  return (
    <Box>
      <FormLogin />
    </Box>
  );
}

export default Login;
