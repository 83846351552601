// ** Mui
import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface Props extends IconButtonProps {
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  fontSize?: 'large' | 'medium' | 'small' | 'inherit';
}

function IconButton({ icon, color = 'primary', fontSize = 'large', ...props }: Props) {
  const Icon = icon;
  return (
    <MuiIconButton color={color} {...props}>
      <Icon fontSize={fontSize} />
    </MuiIconButton>
  );
}

export default IconButton;
