// ** Imports
import { useState } from 'react';
import { useSelector } from 'react-redux';

// ** Import to Hook
import { useFindRestCountries } from '../../../hooks/restCountries';
import { useCreateQuote } from '../../../hooks/orders';
import {
  IQuoteSelectForm,
  IDetailsFormQuote,
  IPlannedDatesFormQuote,
  IActivitiesFormQuote,
  IQuoteArticlesTable,
  IArticlesFormQuote,
  IAddressFormQuote,
} from '../../../models/orders';
import { IAccountFormQuote } from '../../../models/accounts';
import { ICustomerFormQuote } from '../../../models/customer';
import { IProductFormQuote } from '../../../models/products';
import { IServiceFormQuote } from '../../../models/services';
import { IItemsSelectArray, IConfirmDialog } from '../../../models/common';
import { IState } from '../../../models/state';
import { activitiesAdapter, articlesApdater, newQuoteAdapter } from '../../../adapters/orders';

// Const
const viewsList = [
  { view: 0, label: 'Select details' },
  { view: 1, label: 'Invoice To' },
  { view: 2, label: 'Destiny' },
  { view: 3, label: 'Address' },
  { view: 4, label: 'Job Information' },
  { view: 5, label: 'Planned Dates' },
  { view: 6, label: 'Activities' },
  { view: 7, label: 'New Quote' },
];

export const useAddQuote = () => {
  /* ---- Component States ----- */
  const [renderStepper, setRenderStepper] = useState<boolean>(true);
  const [steps, setSteps] = useState([viewsList[0]]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [view, setView] = useState<number>(0);
  const [isAccount, setIsAccount] = useState<boolean>(true);
  const [isOpsLocals, setIsOpsLocals] = useState(false);
  const [countries, setCountries] = useState<IItemsSelectArray[]>([]);
  const [confirmDialog, setConfirmDialog] = useState<IConfirmDialog>({
    isOpen: false,
    title: '',
    subTitle: '',
    onConfirm: () => {
      return null;
    },
  });

  /* ---- Form States ---- */
  const [dataSelects, setDataSelects] = useState<IQuoteSelectForm>();
  const [dataAccount, setDataAccount] = useState<IAccountFormQuote>();
  const [dataAddress, setDataAddress] = useState<IAddressFormQuote>();
  const [dataCustomer, setDataCustomer] = useState<ICustomerFormQuote>();
  const [dataDetails, setDataDetails] = useState<IDetailsFormQuote>();
  const [dataPlannedDates, setDataPlannedDates] = useState<IPlannedDatesFormQuote>();
  const [dataActivities, setDataActivities] = useState<IActivitiesFormQuote>();
  const [dataProduct, setDataProduct] = useState<IProductFormQuote>();
  const [dataService, setDataService] = useState<IServiceFormQuote>();
  const [articles, setArticles] = useState<IQuoteArticlesTable[]>([]);

  /* --- hooks ---- */
  const session = useSelector((state: IState) => state.userSession);
  const [, loading, error] = useFindRestCountries(setCountries);
  const [newQuote] = useCreateQuote();

  /* ---- Funtions ---- */
  const handleNext = () => {
    if ((activeStep === viewsList.length - 1 && isAccount) || (activeStep === viewsList.length - 2 && !isAccount)) {
      return null;
    }

    setView(preView => preView + 1);

    if (activeStep === 0 && !isAccount) {
      setView(2);
      setSteps(prevSteps => [...prevSteps, viewsList[view + 2]]);
    } else {
      setSteps(prevSteps => [...prevSteps, viewsList[view + 1]]);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) return null;

    setView(preView => preView - 1);

    if (activeStep === 1 && !isAccount) {
      setView(0);
    }

    setSteps(steps.slice(0, -1));
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setSteps([viewsList[0]]);
    setActiveStep(0);
    setView(0);
    setIsAccount(false);
    setDataSelects(undefined);
    setDataAccount(undefined);
    setDataCustomer(undefined);
    setDataAddress(undefined);
    setDataDetails(undefined);
    setDataActivities(undefined);
    setDataProduct(undefined);
    setDataService(undefined);
    setArticles([]);
  };

  const productView = (update: boolean) => {
    if (!update) {
      setDataProduct(undefined);
    }
    setRenderStepper(false);
    setView(8);
  };

  const serviceView = (update: boolean) => {
    if (!update) {
      setDataService(undefined);
    }
    setRenderStepper(false);
    setView(9);
  };

  const detailsView = () => {
    setRenderStepper(true);
    setView(7);
  };

  const addArticle = (values: IArticlesFormQuote) => {
    const taxation = values.tax ? values.subTotal * 0.2 : 0;
    const insurance = values.safe ? values.subTotal * 0.2 : 0;

    const newArticle: IQuoteArticlesTable = {
      number: articles.length + 1,
      taxation,
      insurance,
      total: values.subTotal + taxation + insurance,
      ...values,
    };

    setArticles(preArticles => [...preArticles, newArticle]);
    detailsView();
  };

  const viewUpdateArticle = (values: IQuoteArticlesTable) => {
    if (values.article === 'product') {
      setDataProduct(values);
      productView(true);
    } else {
      setDataService(values);
      serviceView(true);
    }
  };

  const updateArticle = (values: IArticlesFormQuote, number: number | undefined) => {
    if (!number) return;

    const taxation = values.tax ? values.subTotal * 0.2 : 0;
    const insurance = values.safe ? values.subTotal * 0.2 : 0;

    const updateArticle: IQuoteArticlesTable = {
      number,
      taxation,
      insurance,
      total: values.subTotal + taxation + insurance,
      ...values,
    };

    setArticles(preArticles => {
      const newArticles = preArticles.map(article => {
        if (article.number === number) {
          return Object.assign(article, updateArticle);
        }
        return article;
      });
      return newArticles;
    });

    detailsView();
  };

  const deleteArticle = (number: number) => {
    const newArticles = articles.filter(article => article.number !== number);
    setArticles(
      newArticles.map((article, index) => {
        return Object.assign(article, { number: index + 1 });
      }),
    );
  };

  const generateQuote = () => {
    const title: string = 'Generate';
    let subTitle: string = 'Do you want to generate this quote?';

    const dataQuote = newQuoteAdapter(
      dataSelects,
      dataAccount,
      dataCustomer,
      dataAddress,
      dataDetails,
      articles,
      isAccount,
      session.id,
    );

    if (!dataQuote) return;
    if (!dataAccount) return;

    if (dataQuote.total < dataAccount.minAmount && dataQuote.typeLocation !== 'L') {
      subTitle = `
      the minimum amount for the order must be ${dataAccount.minAmount}$, if you continue an adjustment will be applied for the difference, do you want to continue?
      `;

      dataQuote.adjustment = (dataAccount.minAmount - dataQuote.total) * 1;
      dataQuote.subtotal = dataQuote.adjustment + dataQuote.subtotal;
      dataQuote.total = dataAccount.minAmount;
    }

    const adaptedArticles = articlesApdater(articles, session.id);
    const adaptedActivities = activitiesAdapter(dataActivities || { activities: [] });

    setConfirmDialog({
      isOpen: true,
      title,
      subTitle,
      onConfirm: () => {
        newQuote(dataQuote, dataPlannedDates || { plannedDates: [] }, adaptedActivities, adaptedArticles);
        handleReset();
      },
    });
  };

  const alertReset = () => {
    setConfirmDialog({
      isOpen: true,
      title: 'Cancel',
      subTitle: 'Do you want to cancel the quote?',
      onConfirm: () => {
        handleReset();
      },
    });
  };

  /* ---- Return ---- */
  const componentStates = {
    steps,
    renderStepper,
    activeStep,
    view,
    isAccount: {
      values: isAccount,
      setValues: (value: boolean) => setIsAccount(value),
    },
    isOpsLocals: {
      values: isOpsLocals,
      setValues: (value: boolean) => setIsOpsLocals(value),
    },
    countries,
    loading,
    error,
    confirmDialog,
    setConfirmDialog,
  };

  const formStates = {
    selects: {
      values: dataSelects,
      setValues: (value: IQuoteSelectForm) => setDataSelects(value),
    },
    account: {
      values: dataAccount,
      setValues: (value: IAccountFormQuote) => setDataAccount(value),
    },
    customer: {
      values: dataCustomer,
      setValues: (value: ICustomerFormQuote) => setDataCustomer(value),
    },
    address: {
      values: dataAddress,
      setValues: (value: IAddressFormQuote) => setDataAddress(value),
    },
    formDetails: {
      values: dataDetails,
      setValues: (value: IDetailsFormQuote) => setDataDetails(value),
    },
    plannedDates: {
      values: dataPlannedDates,
      setValues: (value: IPlannedDatesFormQuote) => setDataPlannedDates(value),
    },
    activities: {
      values: dataActivities,
      setValues: (value: IActivitiesFormQuote) => setDataActivities(value),
    },
    product: {
      values: dataProduct,
      setValues: (value: IProductFormQuote) => setDataProduct(value),
    },
    service: {
      values: dataService,
      setValues: (value: IServiceFormQuote) => setDataService(value),
    },
    articles: {
      values: articles,
      setValues: (value: IQuoteArticlesTable[]) => setArticles(value),
    },
  };

  const functions = {
    handleNext,
    handleBack,
    alertReset,
    productView,
    serviceView,
    detailsView,
    addArticle,
    viewUpdateArticle,
    updateArticle,
    deleteArticle,
    generateQuote,
  };

  return [componentStates, formStates, functions] as const;
};
