// ** MUI Imports
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// ** Icons Imports
import PencilOutline from 'mdi-material-ui/PencilOutline';
import DeleteOutline from 'mdi-material-ui/DeleteOutline';

// Import to Component
import { UsersList } from '../../models/users/users-list.model';

interface RenderCell {
  row: UsersList;
}

export const defaultColumns = (editUser: (value: number) => void, deleteUser: (value: number) => void) => {
  return [
    {
      flex: 0.25,
      minWidth: 230,
      field: 'fullName',
      headerName: 'User',
      renderCell: ({ row }: RenderCell) => {
        const { name } = row;

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Typography
              noWrap
              component='a'
              variant='body2'
              sx={{
                fontWeight: 600,
                color: 'text.primary',
                textDecoration: 'none',
              }}
            >
              {name}
            </Typography>
          </Box>
        );
      },
    },
    {
      flex: 0.25,
      field: 'email',
      minWidth: 250,
      headerName: 'Email',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography noWrap variant='body2'>
            {row.email}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 140,
      field: 'status',
      headerName: 'Status',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.status}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 140,
      field: 'user',
      headerName: 'Username',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.user}
          </Typography>
        );
      },
    },
    {
      flex: 0.15,
      minWidth: 120,
      sortable: false,
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => editUser(row.id)}>
              <PencilOutline fontSize='small' />
            </IconButton>
            <IconButton onClick={() => deleteUser(row.id)}>
              <DeleteOutline fontSize='small' />
            </IconButton>
          </Box>
        );
      },
    },
  ];
};
