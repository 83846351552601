import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// ** MUI Imports
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';

// ** Components
import DialogModal from '../../components/DialogModal/DialogModal.component';

// ** Views
import ProductFilters from '../ProductFilters/ProductFilters.view';

// Import to Component
import { defaultColumns } from './productsModalColumns';
import { IProductFilters, IProductFormQuote } from '../../models/products';
import { IItemsSelectArray } from '../../models/common';
import { useFindProducts } from '../../hooks/products';

interface Props {
  open: boolean;
  dialogTitle: string;
  handleClose: () => void;
  handleProduct: (values: IProductFormQuote) => void;
}

const initialValues = {
  accountId: null,
  type: '',
  name: '',
  description: '',
};

function ProductsModal({ open, dialogTitle, handleClose, handleProduct }: Props) {
  const [items, setItems] = useState<IItemsSelectArray[]>();
  const columns = defaultColumns(handleProduct);

  const methods = useForm<IProductFilters>({
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const filters = methods.watch();

  const [data, loading, error, queryOptions, setPage, setPageSize] = useFindProducts(filters, setItems);

  if (error) return <div>Error</div>;

  return (
    <DialogModal open={open} handleClose={handleClose} dialogTitle={dialogTitle} fullWidth maxWidth='md' scroll='body'>
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Grid item xs={12}>
          <Card>
            <FormProvider {...methods}>
              <form>
                <ProductFilters items={items || []} />
              </form>
            </FormProvider>
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ my: 2 }}>
          <Card sx={{ height: '60vh', overflowY: 'auto' }}>
            <DataGrid
              autoHeight
              rows={data?.products?.rows || []}
              loading={loading}
              rowCount={queryOptions.rowCount}
              checkboxSelection={false}
              pageSize={queryOptions.pageSize}
              page={queryOptions.page}
              disableSelectionOnClick
              columns={columns}
              rowsPerPageOptions={[10, 20, 35]}
              paginationMode='server'
              onPageChange={(newPage: number) => {
                setPage(newPage);
              }}
              onPageSizeChange={(newPageSize: number) => {
                setPage(0);
                setPageSize(newPageSize);
              }}
              pagination
              disableColumnFilter
            />
          </Card>
        </Grid>
      </Grid>
    </DialogModal>
  );
}

export default ProductsModal;
