import React from 'react';

// ** Mui
import { styled } from '@mui/material/styles';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

// ** Components
import ButtonForm from '../ButtonForm/ButtonForm.component';

// ** Interfaces
import { IConfirmDialog } from '../../models/common';

// ** Styles
const Dialog = styled(MuiDialog)(() => ({
  position: 'absolute',
}));

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing(5),
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  justifyContent: 'space-around',
  margin: theme.spacing(5),
}));

// Props
interface Props {
  confirmDialog: IConfirmDialog;
  setConfirmDialog: (values: IConfirmDialog) => void;
}

// ** Component
function ConfirmDialog({ confirmDialog, setConfirmDialog }: Props) {
  return (
    <Dialog open={confirmDialog.isOpen} fullWidth>
      <DialogContent>
        <Typography variant='h6'>{confirmDialog.title}</Typography>
        <Typography variant='subtitle2'>{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions>
        <ButtonForm label='No' color='error' onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} />
        <ButtonForm
          label='Yes'
          color='info'
          onClick={() => {
            confirmDialog.onConfirm();
            setConfirmDialog({
              isOpen: false,
              title: '',
              subTitle: '',
              onConfirm: () => {
                return null;
              },
            });
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
