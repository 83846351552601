import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// ** MUI Imports
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';

// ** Components
import DialogModal from '../../components/DialogModal/DialogModal.component';
import TextForm from '../../components/TextForm/TextForm.component';
import ButtonForm from '../../components/ButtonForm/ButtonForm.component';

// ** Import to Component
import { initialValues, schemeValidation } from './utils/schemeValidation.util';
import { IUpdatePriceArticle } from '../../models/common';

// ** Props
interface Props {
  open: boolean;
  dialogTitle: string;
  handleClose: () => void;
  handleUpdate: (values: IUpdatePriceArticle) => void;
  currentPrice: number;
}

function ChangePrice({ open, dialogTitle, handleClose, handleUpdate, currentPrice }: Props) {
  const methods = useForm<IUpdatePriceArticle>({
    defaultValues: initialValues,
    resolver: yupResolver(schemeValidation),
  });

  const onSubmit = (data: IUpdatePriceArticle) => {
    data.changePrice = true;
    data.beforePrice = currentPrice;
    data.afterPrice = data.price;

    handleUpdate(data);
  };
  return (
    <DialogModal open={open} handleClose={handleClose} dialogTitle={dialogTitle} fullWidth maxWidth='md'>
      <Grid item xs={12}>
        <Card>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <TextForm name='price' label='New Price' />
                  </Grid>
                  <Grid item xs={12}>
                    <TextForm name='reason' label='Reason' />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <ButtonForm label='Submit' type='submit' />
              </CardActions>
            </form>
          </FormProvider>
        </Card>
      </Grid>
    </DialogModal>
  );
}

export default ChangePrice;
