import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// ** Import to Hook
import { schemeValidation, initialValues } from '../utils/schemeValidation.util';
import { IAccountFormQuote } from '../../../models/accounts';
import { useUpdateAccount } from '../../../hooks/accounts';

interface Params {
  nextStep: () => void;
  backStep: () => void;
  values: IAccountFormQuote | undefined;
  setValues: (value: IAccountFormQuote) => void;
}

export const useQuoteAccount = ({ backStep, nextStep, values, setValues }: Params) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const methods = useForm<IAccountFormQuote>({
    defaultValues: values || initialValues,
    resolver: yupResolver(schemeValidation),
  });

  const [updateAccount, data, loading] = useUpdateAccount();

  useEffect(() => {
    if (data && !loading) {
      setDisabled(true);
    }
  }, [data, loading]);

  const onSubmit = (data: IAccountFormQuote) => {
    setValues(data);
    nextStep();
  };

  const handleCancel = () => {
    methods.reset(values);
    setDisabled(true);
  };

  const handleClick = () => {
    setDisabled(false);
  };

  const handleAction = (data: IAccountFormQuote) => {
    updateAccount(data);
  };

  return [methods, onSubmit, disabled, handleCancel, handleClick, handleAction] as const;
};
