// ** MUI Imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

// ** Icons Imports
import PencilOutline from 'mdi-material-ui/PencilOutline';
import DeleteOutline from 'mdi-material-ui/DeleteOutline';

// Import to Component
import { IQuoteArticlesTable } from '../../models/orders';

interface RenderCell {
  row: IQuoteArticlesTable;
}

export const defaultColumns = (
  viewUpdateArticle: (values: IQuoteArticlesTable) => void,
  deleteArticle: (value: number) => void,
) => {
  return [
    {
      flex: 0.15,
      minWidth: 120,
      sortable: false,
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton color='info'>
              <PencilOutline fontSize='small' onClick={() => viewUpdateArticle(row)} />
            </IconButton>
            <IconButton color='error'>
              <DeleteOutline fontSize='small' onClick={() => deleteArticle(row.number)} />
            </IconButton>
          </Box>
        );
      },
    },
    {
      flex: 0.2,
      field: 'number',
      //   minWidth: 250,
      headerName: 'No.',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography noWrap variant='body2'>
            {row.number}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      //   minWidth: 230,
      field: 'id',
      headerName: 'ID',
      renderCell: ({ row }: RenderCell) => {
        const { id } = row;

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Typography
              noWrap
              component='a'
              variant='body2'
              sx={{
                fontWeight: 600,
                color: 'text.primary',
                textDecoration: 'none',
              }}
            >
              {id}
            </Typography>
          </Box>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 200,
      field: 'description',
      headerName: 'Description',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.description}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      //   minWidth: 140,
      field: 'quantity',
      headerName: 'Quantity',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.quantity}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      //   minWidth: 140,
      field: 'unitPrice',
      headerName: 'Unit Price',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.unitPrice}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      //   minWidth: 140,
      field: 'taxation',
      headerName: 'Taxation',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.taxation}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      //   minWidth: 140,
      field: 'insurance',
      headerName: 'Insurance',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.insurance}
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      //   minWidth: 140,
      field: 'total',
      headerName: 'Total',
      renderCell: ({ row }: RenderCell) => {
        return (
          <Typography
            noWrap
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {row.total}
          </Typography>
        );
      },
    },
  ];
};
