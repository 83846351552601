import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// Import to Hook
import { useOrderFindByType } from '../../../hooks/orders';
import { IQuoteList, IQuoteFilters } from '../../../models/orders';

// ** Utils
import { today, getDateSubtractingDays } from '../../../utils/getDates.util';

const initialValues = {
  account: '',
  customer: '',
  status: '1',
  startDate: getDateSubtractingDays(30),
  endDate: today,
};

export const useQuoteList = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [row, setRow] = useState<IQuoteList>();

  const methods = useForm<IQuoteFilters>({
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const navigate = useNavigate();

  const filters = methods.watch();

  const [data, loading, error, queryOptions, setPage, setPageSize] = useOrderFindByType<IQuoteList>('C', filters);

  const handleEdit = () => {
    if (!row) return;
    navigate(`/orders/update/${row.id}`);
  };

  return [
    methods,
    data,
    loading,
    error,
    queryOptions,
    setPage,
    setPageSize,
    open,
    setOpen,
    setRow,
    handleEdit,
  ] as const;
};
