import { gql, useQuery } from '@apollo/client';
import { ICountriesList } from '../../models/restCountries';
import { IItemsSelectArray } from '../../models/common';

interface IFindCountries {
  restCountries: ICountriesList[];
}

const FIND_COUNTRIES = gql`
  query getCountries {
    restCountries {
      alpha2Code
      name
    }
  }
`;

export const useFindRestCountries = (setValues: (values: IItemsSelectArray[]) => void) => {
  const { data, loading, error } = useQuery<IFindCountries>(FIND_COUNTRIES, {
    onCompleted: data => {
      const array: IItemsSelectArray[] = [];
      data.restCountries.map(country => array.push({ value: country.alpha2Code, label: country.name }));
      setValues(array);
    },
  });

  return [data, loading, error] as const;
};
