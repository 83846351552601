import { Navigate, Outlet } from 'react-router-dom';

function PublicRouter({ session }: any) {
  return session ? (
    <Navigate to='/' />
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Outlet />
    </div>
  );
}

export default PublicRouter;
